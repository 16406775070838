import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Snackbar,
} from "@mui/material";
import { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import * as React from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinkIcon from "@mui/icons-material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import copy from "copy-to-clipboard";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import fileListContext from "./context/fileList";
export default function ProjectFile({ data }) {
  const [fileList, setFileList] = useState([]);
  const [checkFile, setCheckFile] = useState([]);
  const [openFile, setOpenFile] = useState([]);
  const [newFolder, setNewFolder] = useState(false);
  const [renameFolder, setRenameFolder] = useState(false);
  const [deleteFolder, setDeleteFolder] = useState(false);
  const [copyFolder, setCopyFolder] = useState(false);
  const [cutFolder, setCutFolder] = useState(false);
  const [pasteFolder, setPasteFolder] = useState(true);
  const [downloadFolder, setDownloadFolder] = useState(false);
  const [uploadFolder, setUploadFolder] = useState(false);
  const [copyPath, setCopyPath] = useState(false);
  const [symLink, setSymLink] = useState(false);
  const [copyData, setCopyData] = useState("");
  const [cutData, setCutData] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const file = useRef(null);
  let newAllCheckbox = [];
  const jobId = data.jobId;

  async function handleFileConfClickOpen() {
    let res = await axios.post("/api/jobFile/treeDir", { jobId });
    setFileList(res.data.data);
  }

  function deepCopy(id, children, fileList) {
    if (typeof fileList != "object") {
      return fileList;
    }
    if (fileList == null) {
      return fileList;
    }
    var newObj = fileList.constructor === Array ? [] : {}; //开辟一块新的内存空间
    for (var i in fileList) {
      if (id === fileList[i].id) {
        fileList[i].children = children;
      }
      newObj[i] = deepCopy(id, children, fileList[i]);
    }
  }

  useEffect(() => {
    handleFileConfClickOpen(jobId);
  }, [jobId]);

  async function handlePasteFile() {
    const type = copyData !== "" ? "copy" : "cut";
    const oldPath = copyData !== "" ? copyData : cutData;
    const newPath =
      checkFile[0].filePath + "/" + oldPath.slice(oldPath.lastIndexOf("/"));
    const res = await axios.post("/api/jobFile/paste", {
      jobId,
      type,
      oldPath,
      newPath,
    });
    // TODO: add Snackbar about the response.
    setFileList(res.data.data);
  }

  async function handleDownloadFile(event) {
    axios
      .post(
        "/api/jobFile/download",
        {
          filePath: checkFile[0].filePath,
          fileName: checkFile[0].data,
        },
        { responseType: "blob" }
      )
      .then((res) => {
        const { data, headers } = res;
        const fileName = headers["content-disposition"].replace(
          /\w+;filename=(.*)/,
          "$1"
        );
        const blob = new Blob([data], { type: headers["content-type"] });
        let dom = document.createElement("a");
        let url = window.URL.createObjectURL(blob);
        dom.href = url;
        dom.download = decodeURI(fileName);
        dom.style.display = "none";
        document.body.appendChild(dom);
        dom.click();
        dom.parentNode.removeChild(dom);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {});
  }

  async function handleNewFolder(jobId, fileName) {
    const folderPath = checkFile[0].filePath;
    const res = await axios.post("/api/jobFile/newFolder", {
      jobId,
      folderPath,
      fileName,
    });
    // TODO: add Snackbar about the response.
    if (res.data.code === 0) {
      setFileList(res.data.data);
    } else {
      setErrorMsg(res.data.msg);
      setErrorAlert(true);
    }
  }

  async function handleDeleteFile(jobId) {
    let res;
    if (checkFile[0].symlinkFolder) {
      res = await axios.post("/api/jobFile/unlink", {
        jobId,
        symLinkFile: checkFile[0].filePath,
      });
    } else {
      res = await axios.post("/api/jobFile/delete", {
        jobId,
        filePath: checkFile[0].filePath,
      });
    }
    // TODO: add Snackbar about the response.
    if (res.data.code === 0) {
      setFileList(res.data.data);
      setCheckFile([]);
      setOpenFile([]);
    } else {
      setErrorMsg(res.data.msg);
      setErrorAlert(true);
    }
  }

  async function handleRenameFolder(jobId, newName) {
    const oldName = checkFile[0].filePath;
    const res = await axios.post("/api/jobFile/rename", {
      jobId,
      oldName,
      newName,
    });
    // TODO: add Snackbar about the response.
    if (res.data.code === 0) {
      setFileList(res.data.data);
    } else {
      setErrorMsg(res.data.msg);
      setErrorAlert(true);
    }
  }

  async function handleUploadFile() {
    let formData = new FormData();
    if (file.current.files.length === 0) return;
    const currentFile = file.current.files[0];
    formData.append("avatar", currentFile, currentFile.name);
    formData.append("filePath", checkFile[0].filePath);
    formData.append("jobId", jobId);
    axios({
      method: "POST",
      url: "/api/jobFile/upload",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      data: formData,
    }).then(function (res) {
      setFileList(res.data.data);
    });
  }

  async function handleSymLinkFolder(jobId, symLinkFile) {
    const sourceFile = checkFile[0].filePath;
    const reg = /\/$/;
    if (reg.test(symLinkFile)) {
      symLinkFile = symLinkFile.slice(0, -1);
    }
    const res = await axios.post("/api/jobFile/symlink", {
      jobId,
      sourceFile,
      symLinkFile,
    });
    if (res.data.code === 0) {
      setFileList(res.data.data);
    } else {
      setErrorMsg(res.data.msg);
      setErrorAlert(true);
    }
  }

  useEffect(() => {
    if (checkFile.length === 1) {
      setDeleteFolder(false);

      setCopyPath(false);
    } else {
      setDeleteFolder(true);
      setCopyPath(true);
    }
    if (checkFile.length === 1 && !checkFile[0].symlinkFolder) {
      setCopyFolder(false);
      setCutFolder(false);
      setRenameFolder(false);
    } else {
      setCopyFolder(true);
      setCutFolder(true);
      setRenameFolder(true);
    }
    if (
      checkFile.length === 1 &&
      checkFile[0].type === "folder" &&
      !checkFile[0].symlinkFolder
    ) {
      setNewFolder(false);
      setDownloadFolder(true);
      setUploadFolder(false);
      setSymLink(false);
    } else {
      setNewFolder(true);
      setDownloadFolder(false);
      setUploadFolder(true);
      setSymLink(true);
    }
    if (
      checkFile.length === 1 &&
      checkFile[0].type !== "folder" &&
      !checkFile[0].symlinkFolder
    ) {
      setDownloadFolder(false);
    } else {
      setDownloadFolder(true);
    }
    if (checkFile.length === 1 && !checkFile[0].symlinkParent) {
      setDeleteFolder(false);
    } else {
      setDeleteFolder(true);
    }
    if ((copyData !== "" || cutData !== "") && checkFile.length === 1) {
      setPasteFolder(false);
    } else {
      setPasteFolder(true);
    }
  }, [checkFile, copyData, cutData]);
  useEffect(() => {
    setCheckFile([]);
  }, [fileList]);
  useEffect(() => {
    setCopyFolder(false);
    setCheckFile([]);
  }, [copyData, cutData]);

  const handleCheckFile = (
    id,
    type,
    data,
    symlinkFolder,
    path,
    symlinkParent
  ) => {
    symlinkParent = symlinkParent || false;
    let arr = checkFile;
    let newarr = [false];
    if (arr.length === 0) {
      arr.push({
        filePath: id,
        type: type,
        data: data,
        symlinkFolder,
        path,
        symlinkParent,
      });
    } else {
      newarr = arr.map((item, index) => {
        if (item.filePath === id) {
          arr.splice(index, 1);
          return true;
        } else {
          return false;
        }
      });
      if (newarr.indexOf(true) >= 0) {
      } else {
        arr.push({
          filePath: id,
          type: type,
          data: data,
          symlinkFolder,
          path,
        });
      }
    }
    setCheckFile((old) => {
      const newArr = [...old];
      return newArr;
    });
  };
  const handleOpenFile = async (id, children) => {
    if (children.length === 0 && id.search("/") > 0) {
      const res = await axios.post("/api/file/readChild", { filePath: id });
      deepCopy(id, res.data.data.children, fileList);
    }
    let arr = openFile;
    let newarr = [];
    if (arr.length === 0) {
      arr.push(id + "");
    } else {
      newarr = arr.map((item, index) => {
        if (item === id) {
          arr.splice(index, 1);
          return true;
        } else {
          return false;
        }
      });
      if (newarr.indexOf(true) >= 0) {
      } else {
        arr.push(id + "");
      }
    }
    setOpenFile((old) => {
      const newArr = [...old];
      return newArr;
    });
  };
  const handleSelectAll = () => {
    const newarr = [];
    selectAll(fileList, newarr);
    setCheckFile(newarr);
  };
  const selectAll = (fileList, newarr) => {
    fileList.children.forEach((item) => {
      newarr.push({
        filePath: item.id,
        type: item.type,
        data: item.data,
      });
      if (item.type === "folder" && item.children.length > 0) {
        selectAll(item, newarr);
      }
    });
  };
  const handleUnSelectAll = () => {
    setCheckFile([]);
  };
  const TreeItem = (dataQuery) => {
    const {
      data,
      children,
      type,
      id,
      size,
      ctime,
      symlinkFolder,
      symlinkParent,
    } = dataQuery.fileList;
    let path = dataQuery.path;
    if (path === "") {
      path = "./" + dataQuery.fileList.id + "/" + data;
    } else {
      path = path + "/" + data;
    }
    const hasChildren = children && children.length > 0;
    const marginNum = dataQuery.marginNum;
    const [checkboxCon, setCheckboxCon] = useState(false);
    const [open, setOpen] = useState(false);
    useEffect(() => {
      for (let i = 0; i < checkFile.length; i++) {
        if (checkFile[i].filePath === id) {
          setCheckboxCon(true);
        }
      }
    }, [checkFile]);
    useEffect(() => {
      for (let i = 0; i < openFile.length; i++) {
        if (openFile[i] === id) {
          setOpen(true);
        }
      }
    }, [openFile]);
    return (
      <Stack>
        <ListItem sx={{ borderBottom: "1px solid #eee" }} disablePadding>
          <Stack direction="row" sx={{ width: "70%", alignItems: "center" }}>
            <ListItemIcon
              onClick={() => {
                handleCheckFile(
                  id,
                  type,
                  data,
                  symlinkFolder,
                  path,
                  symlinkParent
                );
              }}
            >
              {size === "size" ? "" : <Checkbox checked={checkboxCon} />}
            </ListItemIcon>
            <ListItemIcon
              style={{ marginLeft: marginNum * 20 + "px" }}
              onClick={() => {
                handleOpenFile(id, children);
              }}
            >
              {type === "folder" ? (
                !open ? (
                  <ChevronRightIcon color="primary"></ChevronRightIcon>
                ) : (
                  <KeyboardArrowDownIcon color="primary"></KeyboardArrowDownIcon>
                )
              ) : (
                <ArticleIcon color="primary"></ArticleIcon>
              )}
            </ListItemIcon>
            {symlinkFolder ? (
              <ListItemText
                primaryTypographyProps={{
                  color: "blue",
                }}
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  overflow: "hidden",
                }}
                primary={data}
              />
            ) : (
              <ListItemText
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  overflow: "hidden",
                }}
                primary={data}
              />
            )}
          </Stack>

          {type === "folder" ? (
            <ListItemText
              sx={{
                textAlign: "right",
              }}
              primary={children.length + " files"}
            />
          ) : (
            <ListItemText
              sx={{
                textAlign: "right",
              }}
              primary={size}
            />
          )}
          <ListItemText
            sx={{
              textAlign: "right",
            }}
            primary={ctime}
          />
        </ListItem>

        {hasChildren && open && (
          <div>
            {children.map((item, index) => {
              return (
                <TreeItem
                  fileList={item}
                  path={path}
                  key={index + marginNum}
                  marginNum={marginNum + 1}
                />
              );
            })}
          </div>
        )}
      </Stack>
    );
  };

  function NewFolderDia() {
    const [open, setOpen] = React.useState(false);
    const [filename, setFileName] = React.useState("");
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const handleFileName = (event) => {
      setFileName(event.target.value);
    };
    return (
      <div>
        <Tooltip title={"New Folder"}>
          <span>
            <IconButton
              aria-label="primary"
              disabled={newFolder}
              onClick={handleClickOpen}
              color="primary"
            >
              <CreateNewFolderIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>New Name</DialogTitle>
          <DialogContent>
            <DialogContentText>Please enter the name</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="file Name"
              type="text"
              variant="standard"
              value={filename}
              onChange={handleFileName}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleNewFolder(jobId, filename);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function ReNameFolderDia() {
    const [open, setOpen] = React.useState(false);
    const [newName, setNewName] = React.useState("");
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const handleFileName = (event) => {
      setNewName(event.target.value);
    };
    return (
      <div>
        <Tooltip title={"Rename"}>
          <span>
            <IconButton
              aria-label="primary"
              disabled={renameFolder}
              onClick={handleClickOpen}
              color="primary"
            >
              <DriveFileRenameOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>New Name</DialogTitle>
          <DialogContent>
            <DialogContentText>Please enter the name</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="file Name"
              type="text"
              variant="standard"
              value={newName}
              onChange={handleFileName}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleRenameFolder(jobId, newName);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function DeleteFolderDia() {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Tooltip title={"Delete"}>
          <span>
            <IconButton
              aria-label="primary"
              disabled={deleteFolder}
              onClick={handleClickOpen}
              color="primary"
            >
              <DeleteOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Delete File</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to delete the file/folder?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleDeleteFile(jobId);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function SymLinkFolderDia() {
    const [open, setOpen] = useState(false);
    const [symLinkFile, setSymLinkFile] = React.useState("");
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const handleSymLink = (event) => {
      setSymLinkFile(event.target.value);
    };
    return (
      <div>
        <Button
          variant="outlined"
          // disabled={symLink}
          disabled="true"
          onClick={handleClickOpen}
          startIcon={<LinkIcon />}
        >
          SYMLINK
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Symbolic Link</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the absolute path of the source
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Absolute path"
              type="text"
              variant="standard"
              value={symLinkFile}
              onChange={handleSymLink}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleSymLinkFolder(jobId, symLinkFile);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function chooseFun(fileList) {
    fileList.children.forEach((item) => {
      if (item.type === "folder") {
        newAllCheckbox.push(item.id);
        if (fileList.children.length > 0) {
          chooseFun(item);
        }
      }
    });
  }

  const handleCopyFile = () => {
    setCopyData(checkFile[0].filePath);
    setCutData("");
  };
  const handleCutFile = () => {
    setCutData(checkFile[0].filePath);
    setCopyData("");
  };
  const handleCopyPath = () => {
    const filePath = checkFile[0].path;
    copy(filePath);
  };
  const handleCollapseAll = () => {
    newAllCheckbox = [];
    chooseFun(fileList);
    newAllCheckbox.push(fileList.id);
    setOpenFile(newAllCheckbox);
  };
  const handleExpandAll = () => {
    setOpenFile([]);
  };
  const handleClose = (flag) => {
    setErrorAlert(flag);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: "tooltip",
          }}
        >
          <Stack spacing={2} direction="row">
            <NewFolderDia></NewFolderDia>
            <ReNameFolderDia></ReNameFolderDia>
            <DeleteFolderDia></DeleteFolderDia>
            <Tooltip title={"Copy File"}>
              <span>
                <IconButton
                  aria-label="primary"
                  onClick={handleCopyFile}
                  disabled={copyFolder}
                  color="primary"
                >
                  <ContentCopyIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={"Cut"}>
              <span>
                <IconButton
                  aria-label="primary"
                  onClick={handleCutFile}
                  disabled={cutFolder}
                  color="primary"
                >
                  <ContentCutIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={"Paste"}>
              <span>
                <IconButton
                  aria-label="primary"
                  disabled={pasteFolder}
                  onClick={handlePasteFile}
                  color="primary"
                >
                  <ContentPasteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={"Download"}>
              <span>
                <IconButton
                  aria-label="primary"
                  disabled={downloadFolder}
                  onClick={handleDownloadFile}
                  color="primary"
                >
                  <CloudDownloadIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Button
              size="small"
              variant="outlined"
              disabled={uploadFolder}
              component="label"
              color="primary"
            >
              Choose upload file
              <input hidden ref={file} multiple type="file" />
            </Button>
            <Tooltip title={"Upload"}>
              <span>
                <IconButton
                  size="small"
                  variant="contained"
                  onClick={handleUploadFile}
                  // disabled={uploadFolder}
                  disabled="true"
                  component="label"
                  color="primary"
                >
                  <CloudUploadIcon></CloudUploadIcon>
                </IconButton>
              </span>
            </Tooltip>
            <Button
              variant="outlined"
              disabled={copyPath}
              onClick={handleCopyPath}
              startIcon={<ContentCopyIcon />}
            >
              PATH
            </Button>
            <SymLinkFolderDia />
          </Stack>
          <Stack
            spacing={2}
            justifyContent="space-between"
            direction="row"
            sx={{
              paddingRight: "25px",
            }}
          >
            <Stack direction="row">
              {openFile.length > 0 ? (
                <Button
                  size="small"
                  component="label"
                  onClick={handleExpandAll}
                  color="primary"
                >
                  collapse ALL
                </Button>
              ) : (
                <Button
                  size="small"
                  component="label"
                  onClick={handleCollapseAll}
                  color="primary"
                >
                  expand all
                </Button>
              )}
              {checkFile.length === 0 ? (
                <Button
                  size="small"
                  component="label"
                  onClick={handleSelectAll}
                  color="primary"
                >
                  select ALL
                </Button>
              ) : (
                <Button
                  size="small"
                  component="label"
                  onClick={handleUnSelectAll}
                  color="primary"
                >
                  unselect ALL
                </Button>
              )}
            </Stack>
            <Tooltip title={"Refresh"}>
              <span>
                <IconButton
                  aria-label="primary"
                  onClick={() => {
                    handleFileConfClickOpen(jobId);
                  }}
                  color="primary"
                >
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Box>
        <Box>
          {fileList.length === 0 ? (
            <div></div>
          ) : (
            <TreeItem
              fileList={fileList}
              key={0}
              marginNum={0}
              path={""}
            ></TreeItem>
          )}
        </Box>
      </Box>
      <Snackbar
        open={errorAlert}
        autoHideDuration={6000}
        message={errorMsg}
        onClose={() => {
          handleClose(false);
        }}
      />
    </>
  );
}
