import React, { useEffect, useState, useReducer } from "react";
import axios from "axios";
import "./pages.css";
import {
  Autocomplete,
  TextField,
  Select,
  Box,
  FormControl,
  InputLabel,
  Alert,
  Button,
  Checkbox,
  MenuItem,
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Stage, Component } from "react-ngl";
import { styled } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Cookies from "js-cookie";

let itLsImage = ""; //default
let isInteractive = false; // if this visualization is interactive or not...for future use
let interactiveMethod = "";
let indices = "";

export default function Visualization({ data, commands, currProjectInfo }) {
  // fetch for the info for all job of the project
  const jobId = data.jobId;
  const [imagesInfo, setImagesInfo] = useState([]);
  const [imageDict, setImageDict] = useState({});
  const [fileName, setFileName] = useState([]);
  const [needUpdate, forceUpdate] = useReducer((x) => x + 1, 0); // call forceUpdate() when need to refresh Jobs page
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [isSelected, setIsSelected] = useState(
    Array(imagesInfo.length).fill(false)
  );
  const [visualDisable, setVisualDisable] = useState(false);
  const [pickJsonFile, setPickJsonFile] = useState([]);

  const handleChange = (event) => {
    setFileName([event.target.value]);
  };

  useEffect(() => {
    const fetchVisualStatus = async () => {
      let visualStatus = (
        await axios.get("/api/job/getSubmitStatus", {
          params: {
            jobId: jobId,
          },
        })
      ).data;

      setVisualDisable(visualStatus.data === 1 ? true : false);
    };

    const fetchIdxSubmitted = async () => {
      let res = (
        await axios.get("/api/job/selection", {
          params: {
            jobId: jobId,
          },
        })
      ).data;

      if (res.code === 0) {
        let result = Array(imagesInfo.length).fill(false);

        // if res.data.indices is null, set indexes = []
        let indexes = [];
        if (res.data) {
          indexes = res.data.split(",");
        }

        for (let i = 0; i < indexes.length; i++) {
          result[indexes[i]] = true;
        }
        setIsSelected(result);
      }
    };

    async function readJsonFile() {
      let userId_ = localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : Cookies.get("userId");
      try {
        let jsonFileData = await axios.post("/api/job/readJson", {
          userId: userId_,
          projectName: currProjectInfo.projectName,
          commandName: data.commandName,
          jobName: data.jobName,
        });

        let tempCoordinateArray = jsonFileData.data.data.coordinates;
        let coordinateArray = [];
        for (let i = 0; i < tempCoordinateArray.length; i++) {
          coordinateArray[i] = tempCoordinateArray[i].coordinate;
        }

        setPickJsonFile(coordinateArray);
      } catch (e) {
        alert("Failed to read JSON file. " + e);
      }
    }

    if (interactiveMethod === "pick" && visualDisable) {
      readJsonFile();
    }

    fetchVisualStatus();

    if (visualDisable) {
      fetchIdxSubmitted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualDisable, imagesInfo, jobId]);

  useEffect(() => {
    // first render
    fetchPaths();
    return () => {
      setImagesInfo([]); // clear memory when unmounted
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, needUpdate]);

  useEffect(() => {
    //setSendRequest(0);
    setImageDict(updateImageDict(imagesInfo));
  }, [imagesInfo]);

  const fetchPaths = async () => {
    var imgUrls = [];
    let url = "/api/job/jobFiles/";
    let info = (await axios.post(url, { jobId })).data.data;
    let index = 0;
    // filter by "postprocess"
    for (let i = 0; i < info.length; i++) {
      const tempArray = info[i].jobFilePath.split("/");
      console.log(tempArray[tempArray.findIndex((x) => x === "projects") + 4]);
      if (
        tempArray[tempArray.findIndex((x) => x === "projects") + 4] ===
        "postprocess"
      ) {
        imgUrls.push({
          img: info[i].jobFilePath,
          title: info[i].fileName,
          index: index,
        });
        index += 1;
      }
    }
    let imgUrls_ = imgUrls.reverse();
    setImagesInfo(imgUrls_);
  };

  for (let i = 0; i < commands.length; i++) {
    if (data.commandName === commands[i].name) {
      isInteractive = commands[i].interactive === true;
      interactiveMethod = commands[i]["interactive_method"];
      break;
    }
  }

  // return the imageDict based on the param imagesInfo
  function updateImageDict(imagesInfo) {
    var dict = { it: [], ls: [], "3d": [], plain: [] };
    // push images contents to the dirctionary
    if (imagesInfo !== undefined) {
      var name_list = [];
      for (let i = 0; i < imagesInfo.length; i++) {
        name_list = imagesInfo[i].title.split("_");
        if (name_list[0] === "ls") {
          dict.ls.push(imagesInfo[i]);
        } else if (name_list[0].indexOf("it") === 0) {
          dict.it.push(imagesInfo[i]);
        } else if (name_list[0] === "3d") {
          dict["3d"].push(imagesInfo[i]);
        } else {
          dict.plain.push(imagesInfo[i]);
        }
      }
    }
    return dict;
  }

  const saveIndex = async () => {
    let url = "/api/job/selection/";
    let res = (await axios.post(url, { indices, jobId })).data;

    if (res.code === -1) {
      console.error(res.msg);
    } else {
      console.log("saveMessage>>", res.msg);
    }
  };

  // display a group of images are displayed in 1-column format. Used in it and ls.
  const GraghImageTable = (images) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {images.map((item, i) => {
          return (
            <div
              key={i}
              style={{
                position: "relative",
                overflowX: "auto",
                marginTop: "10px",
              }}
            >
              {item.title.includes(".html") ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <iframe
                    style={{ margin: "auto" }}
                    width={1000}
                    height={600}
                    src={item.img}
                    title={item.title}
                  />
                </Box>
              ) : (
                <img
                  style={{
                    objectFit: "cover",
                    margin: "5px",
                  }}
                  src={item.img}
                  srcSet={item.img}
                  alt={item.title}
                  loading="lazy"
                />
              )}
            </div>
          );
        })}
      </Box>
    );
  };

  async function runInteractCommand() {
    let url = "/api/job/runInteractCommand";
    let res = (await axios.post(url, { jobId, indices })).data;

    if (res.code === -1) {
      console.error(res.msg);
    } else {
      console.log("saveMessage>>", res.msg);
    } // print command info
  }

  async function updateSubmissionStatus() {
    let url = "/api/job/submit";
    let res = (await axios.post(url, { jobId })).data;

    if (res.code === -1) {
      console.error(res.msg);
    } else {
      console.log("saveMessage>>", res.msg);
    }
  }

  const handleClick = async () => {
    indices = selectedIndex.toString();
    await saveIndex();
    setVisualDisable(true);
    await runInteractCommand();
    await updateSubmissionStatus();
  };

  const selectImageTable = (images) => {
    return (
      <div>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {images.map(
            (item, i) =>
              !item.title.includes(".html") && (
                <div
                  key={i}
                  style={{
                    position: "relative",
                    overflowX: "auto",
                    margin: "10px 5px 0 5px",
                  }}
                >
                  <Checkbox
                    disabled={visualDisable}
                    checked={!!isSelected[item.index]}
                    onClick={() => getIndex(item.index)}
                    sx={{
                      position: "absolute",
                      "& .MuiSvgIcon-root": { fontSize: 40, color: "#0066ff" },
                    }}
                  />
                  <img
                    src={item.img}
                    srcSet={item.img}
                    alt={item.title}
                    loading="lazy"
                  />
                </div>
              )
          )}
        </Box>
        <Button
          variant="outlined"
          onClick={handleClick}
          disabled={selectedIndex.length === 0 || visualDisable}
          sx={{ margin: "10px" }}
        >
          Submit
        </Button>
      </div>
    );
  };

  function getIndex(index) {
    isSelected[index] = !isSelected[index];
    setIsSelected(isSelected);
    updateIndex(isSelected);
  }

  function updateIndex(lst) {
    let result = [];
    for (let i = 0; i < lst.length; i++) {
      if (lst[i] === true) {
        result.push(i);
      }
    }
    setSelectedIndex(result);
  }

  // allow users to select a file name which will be used as the image in ls-display
  const lsSelect = (images, handleChange) => (
    //<FormControl fullwidthvariant='outlined' sx={{ m: 1, width: "30%"}}>
    <FormControl sx={{ m: 1, width: "80%" }} size="small">
      <InputLabel>File Name</InputLabel>

      <Select
        value={fileName.length === 0 ? images[0].title : fileName} // default the first one
        onChange={handleChange}
        label="File Name"
      >
        {images.map((imageInfo) => (
          <MenuItem
            key={imageInfo.index}
            value={imageInfo.title}
            sx={{ minWidth: 200 }}
          >
            {imageInfo.title.split(".")[0].slice(3)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  // display the image selected by ls_select
  const lsDisplay = (image) => {
    return image.slice(-5) === ".html" ? (
      <iframe width={1000} height={600} src={image} title={image} />
    ) : (
      <Box
        sx={{
          overflowX: "auto",
          p: 1,
        }}
      >
        {" "}
        <img src={image} loading="lazy" alt="" />
      </Box>
    );
  };

  return (
    <div className="body">
      <Stack direction="row">
        <Tooltip title={"Refresh"} style={{ float: "right" }}>
          <span>
            <IconButton
              aria-label="primary"
              onClick={forceUpdate}
              color="primary"
              sx={{ ml: "1050px", position: "absolute", top: 100 }}
            >
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
      {imagesInfo.length > 0 ? (
        <Box>
          {imageDict.it.length !== 0 && (
            <ItBox
              lsSelect={lsSelect}
              lsDisplay={lsDisplay}
              GraghImageTable={GraghImageTable}
              imagesInfo={imagesInfo}
              imageDict={imageDict}
              fileName={fileName}
              handleChange={handleChange}
            />
          )}
          {imageDict.ls.length !== 0 && !isInteractive && (
            <LsBox
              imageDict={imageDict}
              lsSelect={lsSelect}
              lsDisplay={lsDisplay}
              lsImages={imageDict.ls}
              fileName={fileName}
              handleChange={handleChange}
            />
          )}

          {imageDict["3d"].length !== 0 && !isInteractive && (
            <ThreeDBox imageDict={imageDict} lsDisplay={lsDisplay} />
          )}

          {imageDict.plain.length !== 0 && !isInteractive && (
            <PlainBox
              GraghImageTable={GraghImageTable}
              plainImages={imageDict.plain}
            />
          )}

          {imagesInfo.LENGTH !== 0 &&
            isInteractive &&
            interactiveMethod === "select" && (
              <PlainBox
                GraghImageTable={selectImageTable}
                plainImages={imagesInfo}
              />
            )}
          {imagesInfo.LENGTH !== 0 &&
            isInteractive &&
            interactiveMethod === "pick" && (
              <PickOptions
                imagesInfo_={imagesInfo}
                data_={data}
                currProjectInfo_={currProjectInfo}
                visualDisable={visualDisable}
                pickJsonFile_={pickJsonFile}
                forceUpdate_={forceUpdate}
              />
            )}
        </Box>
      ) : (
        <Alert severity="info">No Image Avaliable</Alert>
      )}
    </div>
  );
}

//If the interactive_method is pick
function PickOptions({
  imagesInfo_,
  data_,
  currProjectInfo_,
  visualDisable,
  pickJsonFile_,
  forceUpdate_,
}) {
  let titles = [];
  const [index, setIndex] = useState(undefined);
  const [scaleValue, setScaleValue] = useState(1);
  const [pickedP, setPickedP] = useState(pickJsonFile_);

  for (let i = 0; i < imagesInfo_.length; i++) {
    titles.push(imagesInfo_[i].title);
  }

  //Display imges used to pick
  function displayImage(index_, scaleFactor, ifClear) {
    let picked;

    //If clear circle, set picked []
    if (ifClear) {
      picked = [];
      pickedP[index_] = [];
    }

    //If coordinates were read from json file
    else if (pickJsonFile_.length !== 0) {
      pickedP[index_] = pickJsonFile_[index_];
    } else {
      if (pickedP[index_]) {
        picked = [...pickedP[index_]];
      } else {
        picked = [];
      }
    }
    const radius = 12;
    //create canvas
    document.getElementById("test").innerHTML =
      "<canvas id = " + index_ + " ></canvas>";

    //Put image on canvas
    if (index_ !== undefined) {
      let canvas = document.getElementById(index_);
      if (canvas) {
        const img = new Image();
        img.src = imagesInfo_[index_].img;
        let ctx = canvas.getContext("2d");
        if (ctx) {
          img.onload = function () {
            img.width = img.width * scaleFactor;
            img.height = img.height * scaleFactor;
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);

            //Draw original circles if zoom in or out
            if (pickedP[index_]) {
              for (let i = 0; i < pickedP[index_].length; i++) {
                ctx.beginPath();
                ctx.arc(
                  pickedP[index_][i].x * scaleFactor,
                  pickedP[index_][i].y * scaleFactor,
                  radius * scaleFactor,
                  0,
                  2 * Math.PI
                );
                ctx.strokeStyle = "red";
                ctx.stroke();
              }
            }
          };
        }

        if (!visualDisable) {
          //left click mouse, draw a circle
          canvas.addEventListener("click", function (event) {
            // Get the mouse click position relative to the canvas
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            ctx.beginPath();
            ctx.arc(x, y, radius * scaleFactor, 0, 2 * Math.PI);
            ctx.strokeStyle = "red";
            ctx.stroke();
            picked.push({ x: x / scaleFactor, y: y / scaleFactor });

            //Save picked coordinate
            let tempArray = [...pickedP];
            tempArray[index_] = [...picked];
            setPickedP(tempArray);
          });
        }

        if (!visualDisable) {
          //Right click mouse, remove a circle
          canvas.addEventListener("contextmenu", function (event) {
            // Prevent the default context menu from appearing
            event.preventDefault();

            // Get the mouse click position relative to the canvas
            const rect = canvas.getBoundingClientRect();
            const x_ = event.clientX - rect.left;
            const y_ = event.clientY - rect.top;

            for (let i = 0; i < picked.length; i++) {
              //Find the circle to be removed, then delete that coordinate from array
              if (
                x_ <= (picked[i].x + radius) * scaleFactor &&
                x_ >= (picked[i].x - radius) * scaleFactor &&
                y_ <= (picked[i].y + radius) * scaleFactor &&
                y_ >= (picked[i].y - radius) * scaleFactor
              ) {
                picked.splice(i, 1);

                let tempArray = [...pickedP];
                tempArray[index_] = [...picked];
                setPickedP(tempArray);

                //reset the canvas
                if (canvas) {
                  const img2 = new Image();
                  img2.src = imagesInfo_[index_].img;
                  if (ctx) {
                    img2.onload = function () {
                      img2.width = img2.width * scaleFactor;
                      img2.height = img2.height * scaleFactor;
                      canvas.width = img2.width;
                      canvas.height = img2.height;
                      ctx.drawImage(img2, 0, 0, img2.width, img2.height);
                      for (let i = 0; i < picked.length; i++) {
                        ctx.beginPath();
                        ctx.arc(
                          picked[i].x * scaleFactor,
                          picked[i].y * scaleFactor,
                          radius * scaleFactor,
                          0,
                          2 * Math.PI
                        );
                        ctx.strokeStyle = "red";
                        ctx.stroke();
                      }
                    };
                  }
                }
                break;
              }
            }
          });
        }
      }
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function updateSubmissionStatus() {
    let url = "/api/job/updateSubmissionStatus";
    let jobId = data_.jobId;
    let res = (await axios.post(url, { jobId })).data;

    if (res.code === -1) {
      console.error(res.msg);
    } else {
      console.log("saveMessage>>", res.msg);
    }
  }

  //When user click submit button, create json file
  async function submit() {
    if (pickedP.length !== 0 && data_.jobStatus === "FINISHED") {
      let userId_ = localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : Cookies.get("userId");

      let pickedInfo = [];
      for (let i = 0; i < titles.length; i++) {
        //Get image names
        let name = titles[i];
        name = name.substring(3);
        let tempArray = name.split(".");
        name = tempArray[0];
        pickedInfo[i] = { coordinate: pickedP[i], imageName: name };
      }

      let picked_ = { coordinates: pickedInfo };
      let jsonData = JSON.stringify(picked_);

      //sent data to backend for json file saving
      try {
        await axios.post("/api/job/saveJson", {
          jsonString: jsonData,
          userId: userId_,
          projectName: currProjectInfo_.projectName,
          commandName: data_.commandName,
          jobName: data_.jobName,
        });
        forceUpdate_();
      } catch (e) {
        alert("Failed to save coordinates. " + e);
      }

      await updateSubmissionStatus();
    } else if (pickedP.length !== 0 && data_.jobStatus !== "FINISHED") {
      alert("Can not submit! Job is running or failed!");
    } else {
      alert("No coordinate was picked!");
    }
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Autocomplete
          disablePortal
          options={titles}
          sx={{ width: "60%" }}
          onChange={(event, newValue) => {
            if (newValue) {
              setIndex(titles.indexOf(newValue));
              displayImage(titles.indexOf(newValue), 1, false);
            } else {
              setIndex(undefined);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Choose an Image" size="small" />
          )}
        />
        <Tooltip title={"Zoom out"} placement="top">
          <IconButton
            aria-label="primary"
            onClick={() => {
              let newcSale = scaleValue - 0.25;
              setScaleValue(newcSale);
              displayImage(index, newcSale, false);
            }}
            color="primary"
            sx={{ marginLeft: "60px" }}
          >
            <ZoomOutIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Zoom in"} placement="top">
          <IconButton
            aria-label="primary"
            onClick={() => {
              let newcSale = scaleValue + 0.25;
              setScaleValue(newcSale);
              displayImage(index, newcSale, false);
            }}
            color="primary"
            sx={{ marginLeft: "15px" }}
          >
            <ZoomInIcon />
          </IconButton>
        </Tooltip>
        <Button
          variant="outlined"
          sx={{ marginLeft: "60px", height: "30px" }}
          size="small"
          onClick={handleClickOpen}
          disabled={visualDisable}
        >
          Submit
        </Button>{" "}
        <Button
          variant="outlined"
          sx={{ marginLeft: "40px", height: "30px" }}
          size="small"
          onClick={() => displayImage(index, scaleValue, true)}
          disabled={visualDisable}
        >
          Clear
        </Button>{" "}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you are done? "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No further edits allowed after submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submit} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div id="test" style={{ overflowX: "auto", marginTop: "20px" }}></div>
    </div>
  );
}

function ItBox({
  GraghImageTable,
  imagesInfo,
  imageDict,
  fileName,
  lsSelect,
  lsDisplay,
  handleChange,
}) {
  function updateFilteredItDict(imagesInfo, prefix) {
    var dict = { all: [], ls: [], "3d": [], plain: [] };
    for (let i = 0; i < imagesInfo.length; i++) {
      var nameList = imagesInfo[i].title.split("_");
      if (nameList[0] === prefix) {
        dict.all.push(imagesInfo[i]);
        if (nameList[1] === "ls") {
          dict.ls.push(imagesInfo[i]);
        } else if (nameList[1] === "3d") {
          dict["3d"].push(imagesInfo[i]);
        } else {
          dict.plain.push(imagesInfo[i]);
        }
      }
    }
    return dict;
  }

  // retrieve prefixs by images names
  function retrievePrefixs(imageDict) {
    var prefixs = [];
    if (imageDict.it !== undefined) {
      for (var i = 0; i < imageDict.it.length; i++) {
        var cur_perfix = imageDict.it[i].title.split("_")[0];
        if (!prefixs.includes(cur_perfix)) {
          prefixs.push(cur_perfix);
        }
      }
    }
    return prefixs;
  }

  //return the it*-ls* image
  function updateItLsImage(filteredItDict, itLsImage, fileName) {
    let defaultItLsImage = "";
    if (filteredItDict !== undefined && filteredItDict.ls.length !== 0) {
      if (itLsImage === "") {
        defaultItLsImage = filteredItDict.ls[0].img;
      }
      for (let i = 0; i < filteredItDict.ls.length; i++) {
        if (filteredItDict.ls[i].title === fileName[0]) {
          defaultItLsImage = filteredItDict.ls[i].img;
        }
      }
    }
    return defaultItLsImage;
  }

  const prefixs = retrievePrefixs(imageDict);
  const [autoCompleteValue, setAutoCompleteValue] = useState(
    prefixs[prefixs.length - 1]
  );

  let filteredItDict = updateFilteredItDict(imagesInfo, autoCompleteValue);
  itLsImage = updateItLsImage(filteredItDict, itLsImage, fileName);

  // dropdown menu with search functionality
  function ItDropdown({ prefixs, autoCompleteValue, setAutoCompleteValue }) {
    return (
      <Autocomplete
        value={autoCompleteValue}
        selectOnFocus
        clearOnBlur
        onChange={(event, newValue) => {
          setAutoCompleteValue(newValue);
        }}
        options={prefixs}
        sx={{ width: "200", p: 1 }}
        renderInput={(params) => (
          <TextField {...params} label="Select Prefix" size="small" />
        )}
      />
    );
  }

  return (
    <Box>
      <Box>
        <ItDropdown
          prefixs={prefixs}
          autoCompleteValue={autoCompleteValue}
          setAutoCompleteValue={setAutoCompleteValue}
        />
      </Box>
      {/* render plain resource */}
      <Box>{GraghImageTable(filteredItDict.plain)}</Box>
      {/* redner 3d resource */}
      <Box>
        <ThreeDBox imageDict={filteredItDict} />
      </Box>
      {/* render ls resource */}
      <Box>
        {filteredItDict.ls.length > 0
          ? lsSelect(filteredItDict.ls, handleChange)
          : []}
        {filteredItDict.ls.length > 0 ? lsDisplay(itLsImage) : []}
      </Box>
    </Box>
  );
}

function LsBox({
  imageDict,
  lsSelect,
  lsDisplay,
  lsImages,
  fileName,
  handleChange,
}) {
  var lsImage = ""; //default

  function updateLsImage(imageDict) {
    if (imageDict.ls !== undefined && imageDict.ls.length !== 0) {
      if (lsImage === "") {
        lsImage = imageDict.ls[0].img;
      }
      for (let i = 0; i < imageDict.ls.length; i++) {
        if (imageDict.ls[i].title === fileName[0]) {
          lsImage = imageDict.ls[i].img;
        }
      }
    }
    return lsImage;
  }

  const Item = styled("div")({
    textAlign: "left",
  });

  return (
    <>
      {lsImages && lsImages.length > 0 && (
        <>
          <Stack spacing={2} justifyContent="center">
            <Item>{lsSelect(lsImages, handleChange)}</Item>
            <Item>{lsDisplay(updateLsImage(imageDict))}</Item>
          </Stack>
        </>
      )}
    </>
  );
}

// display all the images in the plain section
function PlainBox({ GraghImageTable, plainImages }) {
  return (
    <>
      {plainImages && plainImages.length !== 0 && (
        <>
          <Box>{GraghImageTable(plainImages)}</Box>
        </>
      )}
    </>
  );
}

// display 3d section
function ThreeDBox({ imageDict, lsDisplay }) {
  return (
    <>
      {imageDict["3d"].map((item, index) => {
        return (
          <Box
            key={index}
            sx={{ p: 1 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stage width="90%" height="500px" backgroundColor="white">
              <Component
                path={item.img}
                reprList={[
                  {
                    type: "cartoon",
                  },
                ]}
              />
            </Stage>
          </Box>
        );
      })}
    </>
  );
}
