import { useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import {
  TextField,
  Button,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

export default function ChangePassword() {
  function Form() {
    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    function validateForm() {
      let isValid = true;

      if (!oldPassword) {
        setOldPasswordError("Old password is required");
        isValid = false;
      } else {
        setOldPasswordError("");
      }

      if (!newPassword) {
        setNewPasswordError("New password is required");
        isValid = false;
      } else {
        setNewPasswordError("");
      }

      if (!confirmNewPassword || newPassword !== confirmNewPassword) {
        setConfirmNewPasswordError("Passwords must match");
        isValid = false;
      } else {
        setConfirmNewPasswordError("");
      }

      return isValid;
    }

    async function changePassword() {
      if (validateForm()) {
        // Get the current userId
        let userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : Cookies.get('userId');
        const form = {
          userId: userId,
          oldPassword: oldPassword,
          newPassword: newPassword,
        };

        setOpen(true);
        const response = await axios.post("/api/user/changePassword", form);
        setMessage(response.data.msg);

        if (response.data.code === 0) {
          setTimeout(() => {
            window.location.href = "/main";
          }, 500);
        }
      }
    }

    return (
      <Paper
        sx={{
          width: "300px",
          height: "400px",
          display: "flex",
          padding: "20px",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "center",
        }}
      >
        {/* <Typography variant="h5" component="div" gutterBottom>
          Change Password
        </Typography> */}
        <TextField
          error={!!oldPasswordError}
          helperText={oldPasswordError}
          label="Old Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setOldPassword(e.target.value)}
          value={oldPassword}
        />
        <TextField
          error={!!newPasswordError}
          helperText={newPasswordError}
          label="New Password"
          type="password"
          autoComplete="new-password"
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <TextField
          error={!!confirmNewPasswordError}
          helperText={confirmNewPasswordError}
          label="Confirm New Password"
          type="password"
          autoComplete="new-password"
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          value={confirmNewPassword}
        />
        <Button variant="contained" onClick={changePassword} disabled="true">
          Change Password
        </Button>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => {
            setOpen(false);
          }}
          message={message}
        />
      </Paper>
    );
  }

  return (
    <Box
      sx={{
        margin: "100px auto",
        padding: "30px",
        width: "300px",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      >
  <Form></Form>
</Box>
);
}