import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const NotFound = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Box textAlign="center">
        <Typography variant="h3" component="h1" color="error">
          404 Not Found
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="text.primary"
          sx={{ marginTop: 2 }}
        >
          The page you are looking for does not exist.
        </Typography>
        <Button variant="outlined" href="/main" sx={{ marginTop: 2 }}>
          Go to Home Page
        </Button>
      </Box>
    </Grid>
  );
};

export default NotFound;