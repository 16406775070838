import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "./pages.css";
import { Alert, Stack, AlertTitle } from "@mui/material";

export default function Overview({ data, setSnackbarOpen, setSnackbarMes }) {
  const [stdErr, setStdErr] = useState("");
  const [stdOutput, setStdOutput] = useState("");

  useEffect(() => {
    const fetchStdInfo = async (jobId) => {
      try {
        let jobsInfo = (
          await axios.post("/api/job/getOverviewInfo", {
            jobId,
          })
        ).data;

        if (jobsInfo.code === 0) {
          setStdErr(jobsInfo.data["stderr"]);
          setStdOutput(jobsInfo.data["stdout"]);
          const needStopFetch =  jobsInfo.data["jobStatus"] === "FAILED" ||
              jobsInfo.data["jobStatus"] === "FINISHED"
          if (needStopFetch) clearInterval(interval);
        } else {
          setSnackbarOpen(true);
          setSnackbarMes("Failed to retrive std update");
        }
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMes(error.toString());
      }
    };

    // refresh render
    const interval = setInterval(async () => {
      await fetchStdInfo(data.jobId);
      return () => clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval); // unmount to prevent memory leak
    // eslint-disable-next-line
  }, []);

  return (
    <div className="body">
      <Stack
        sx={{ width: "100%", whiteSpace: "pre-wrap", wordBreak: "break-all" }}
        spacing={2}
      >
        {stdErr && (
          <Alert severity="error">
            <AlertTitle>Standard Error</AlertTitle>
            {stdErr}
          </Alert>
        )}
        <Alert severity="success">
          <AlertTitle>Standard Output</AlertTitle>
          {/* Duration: {data['finishTime'] - data['startTime']} ms */}
        </Alert>
        <Alert id="box" variant="outlined" severity="success" icon={false}>
          {stdOutput}
        </Alert>
      </Stack>
    </div>
  );
}
