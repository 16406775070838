import { useReducer, useState } from "react";
import { Paper, Stack} from "@mui/material";

import CommandRunner from "./../CommandRunner/CommandRunner";
import ItemInWindow from "./../ItemInWindow/ItemInWindow";

import "../../../index.css";

//Discard the parameter 'projectName', if you need to call 'projectName', please use the parameter 'currProjectInfo.projectName'
export default function ProjectDisplay({ commands }) {
  const [needUpdate, forceUpdate] = useReducer((x) => x + 1, 0); // call forceUpdate() when need to refresh Jobs page
  const [cloneCmdArgs, setCloneCmdArgs] = useState(undefined);

  return (
    <Stack direction="row">
      <Paper
        variant="outlined"
        sx={{
          p: 1,
          mt: "5px",
          width: "72%",
        }}
      >
        <ItemInWindow
          commands={commands}
          needUpdate={needUpdate}
          forceUpdate={forceUpdate}
          setCloneCmdArgs={setCloneCmdArgs}
        />
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          p: "10px",
          mt: "5px",
          ml: "5px",
          width: "28%",
        }}
      >
        <CommandRunner
          commands={commands}
          forceUpdate={forceUpdate}
          inputData={cloneCmdArgs}
          fillCloneCmdArgs={cloneCmdArgs !== undefined}
        />
      </Paper>
    </Stack>
  );
}
