import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

import FileDisplay from "../FileDisplay/FileDisplay";
import ProjectInfo from "./Components/ProjectInfo";
import ProjectCreate from "./Components/ProjectCreate";
import ProjectDelete from "./Components/ProjectDelete";
import ProjectArchive from "./Components/ProjectArchive";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCurrProjectInfo } from "../currProjectInfoSlice";

export default function ProjectManager({ project, projectId, setProject }) {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allProjectInfo, setAllProjectInfo] = useState(undefined);
  const [fileListBtn, setFileListBtn] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProjects();
    return () => {
      setProjects([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchProjects() {
    let userId;

    //If user just logged in
    if (localStorage.getItem("userId") !== null) {
      userId = localStorage.getItem("userId");
    } else {
      //If user was remembered for 7 days
      userId = Cookies.get("userId");
    }

    const ps = await axios.post("/api/project/projects", { userId });
    setIsLoading(false);
    if (ps.data.code === 0) {
      setAllProjectInfo(ps.data.data);
      const projectList = ps.data.data.map((info) => info.projectName);
      setProjects(projectList);
      setFileListBtn(false);
      // set the autocomplete to desirable project
      if (project === undefined) {
        const queryParams = new URLSearchParams(window.location.search);
        const preSelectProjectId = queryParams.get("projectId");
        let preSelectProject = ps.data.data.filter(
          (projectInfo) => projectInfo.projectId === preSelectProjectId
        )[0];

        if (preSelectProject !== undefined) {
          // when we have a preselect project from URL
          setProject(preSelectProject.projectName);
          dispatch(updateCurrProjectInfo(preSelectProject));
        } else {
          // default to the first project in autocomplete
          setProject(projectList[0]);
          const preSelectProject = ps.data.data.filter(
            (info) => info.projectName === projectList[0]
          )[0];
          dispatch(updateCurrProjectInfo(preSelectProject));
        }
      } else {
        // when create new project, select last on the list
        setProject(projectList[0]);
        const projectInfo = ps.data.data.filter(
          (info) => info.projectName === projectList[ps.data.data.length - 1]
        )[0];
        dispatch(updateCurrProjectInfo(projectInfo));
      }
    }
  }

  async function handleCreateProject(projectName) {
    await fetchProjects();
    setProject(projectName);
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          {projects.length !== 0 ? (
            <Autocomplete
              size="small"
              value={project || projects[0]}
              onChange={(e, v) => {
                setProject(v);
                const newCurrJobInfo = allProjectInfo.filter(
                  (info) => info.projectName === v
                )[0];
                dispatch(updateCurrProjectInfo(newCurrJobInfo));

                navigate(
                  "/project/?projectId=" +
                    allProjectInfo.filter((info) => info.projectName === v)[0]
                      .projectId
                );
              }}
              options={projects}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Project" />
              )}
              disableClearable
            />
          ) : (
            <Typography>No project in the database</Typography>
          )}

          <ProjectCreate
            projects={projects}
            onCreateProject={handleCreateProject}
          />
          <ProjectArchive
            setProject={setProject}
            fetchProjects={fetchProjects}
            projectId={projectId}
            disabledBtn={projects.length === 0}
          />
          <ProjectDelete
            setProject={setProject}
            fetchProjects={fetchProjects}
            projectId={projectId}
            disabledBtn={projects.length === 0}
          />
          <ProjectInfo disabledBtn={projects.length === 0} />
          <FileDisplay
            fileListBtn={fileListBtn}
            projectId={projectId}
          ></FileDisplay>
        </Stack>
      )}
    </>
  );
}
