import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Main from "./pages/main/Main";
import Project from "./pages/project/Project";
import UserManage from "./pages/userManage/UserManage";
import MainLayout from "./layouts/MainLayOut/MainLayOut";
import ChangePassword from "./pages/ChangePassword";
import NotFound from "./pages/NotFound";
import YamlHub from "./pages/yamlHub/YamlHub";

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/signup", element: <SignUp /> },
    { path: "/change-password", element: <ChangePassword /> },
    {
      element: <MainLayout />,
      children: [
        { path: "/main", element: <Main /> },
        { path: "/project", element: <Project /> },
        { path: "/user-manage", element: <UserManage /> },
        { path: "/yaml-hub", element: <YamlHub /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
