import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Button,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  TextField,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

import ReplayIcon from "@mui/icons-material/Replay";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ViewList from "@mui/icons-material/ViewList";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@mui/styles";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import CloseIcon from "@mui/icons-material/Close";

import { useSelector } from "react-redux";

export default function PreviewCommandRunner({
  commands,
  forceUpdate,
  fillCloneCmdArgs = false,
  inputData,
}) {
  const [commandIdx, setCommandIdx] = useState(0);
  const [outputPath, setOutputPath] = useState(undefined);
  const [argValues, setArgValues] = useState([]);
  const [jobName, setJobName] = useState(undefined);

  //store index of file paths type arguments, to get parent job name
  const [filePathIndex, setFilePathIndex] = useState([]);

  //These two hooks used to store the arguments' correct sequence
  //Note: the sequence of arguments in YAML file is the same as arguments submitted to ddatabase, BUT different form the UI display
  const [originalIndex, setOriginalIndex] = useState([]);

  const currProjectInfo = useSelector((state) => state.currProjectInfo.value);

  useEffect(() => {
    // set to correct command
    let x = getCommandDefault(fillCloneCmdArgs, inputData);
    setCommandIdx(x);

    //Since find levels function has changed the sequence of the arguments, before fill the arguments, find the correct sequence
    let levels2 = FindLevels(commands[x].arguments);

    //This function is used to find the index of each argument
    function FindIndex(argumentsList) {
      let k = 0;
      //store the index of each argument
      let argIndex = [];
      for (let i = 0; i < argumentsList.length; i++) {
        Object.assign(argumentsList[i], { argIndex: i });
      }
      for (let j = 0; j < levels2.length; j++) {
        for (let p = 0; p < argumentsList.length; p++) {
          if (argumentsList[p].level === levels2[j]) {
            if (argumentsList[p].arg_type === "select") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "file_path") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "folder") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "output_path") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "output_path_fn") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "file") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "bool") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "str") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "float") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else if (argumentsList[p].arg_type === "int") {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            } else {
              argIndex[k] = argumentsList[p].argIndex;
              k = k + 1;
            }
          }
        }
      }
      return argIndex;
    }

    if (fillCloneCmdArgs && inputData !== undefined) {
      let clonArgValues = JSON.parse(inputData["commandArgs"]);
      let temp = [];

      let argIndex = FindIndex(commands[x].arguments);

      //the arguments fetched from database should have the same sequence with arguments in YAML file
      for (let i = 0; i < argIndex.length; i++) {
        temp[i] = clonArgValues[argIndex[i]];
      }

      //set cloned arguments
      setArgValues([...temp]);
      setFilePathIndex([]);
      sessionStorage.clear();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  useEffect(() => {
    if (currProjectInfo !== undefined) {
      fetchOutputPath();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currProjectInfo, commands, commandIdx, jobName]);

  function getCommandDefault(fillCloneCmdArgs, inputData) {
    // when disableAll, use the command from input data, else uses the first cmd on list
    if (fillCloneCmdArgs) {
      // using the commandName to find its cmdIndex, used for filling the command from inputData as default
      for (let i in commands) {
        if (commands[i]["custom_name"] === inputData["customName"])
          return parseInt(i, 10);
      }
    }
    return 0;
  }

  //When another tool is selected, change commands's index.
  //set "argValues"(store arguments submitted to database) to impty
  //Set level (tab value) to basic
  function changeCommand(idx) {
    setCommandIdx(idx);
    setArgValues([]);
    setOriginalIndex([]);
    setTabValue("0");
    sessionStorage.clear();
    setFilePathIndex([]);
    setJobName(undefined);
  }

  //When click "Reset all" icon, set all arguments default values
  function setDefaults() {
    setArgValues([]);
    setOriginalIndex([]);
    setFilePathIndex([]);
    sessionStorage.clear();
  }

  //Fetch output path data from server
  async function fetchOutputPath() {
    try {
      await axios
        .post("/api/job/jobOutputPath", {
          projectId: currProjectInfo.projectId,
          commandName: commands[commandIdx].name,
        })
        .then((response) => {
          setOutputPath(response.data);
        });
    } catch (e) {}
  }

  //---------------------------------Below: for tool selection-----------------------------------------------------------

  //button disable state and text field value state, for tool select
  const [disablesState, setDisablesState] = useState(true);
  const [tempValue] = useState(commands[0].custom_name);
  const [tempIndex] = useState(0);

  //Handle tool modal open or close, used to select tool from too list
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDisablesState(true);
  };

  //Handle enpand and Collapse the tree view, note: "event" variable is not used, but cannot be deleted
  const [expanded, setExpanded] = useState([]);

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? folderId : []));
  };

  //Set tool when click confirm button, then disable confirm buttom,
  //change command index, close tool popover, arguments values undefined, and set level basic,clear session
  function getToolName() {
    document.querySelector("#inputTools").value = tempValue;
    setOpen(false);
    setCommandIdx(tempIndex);
    setDisablesState(true);
    setArgValues([]);
    setOriginalIndex([]);
    setTabValue("0");
    sessionStorage.clear();
  }

  //Get the tool directory tree information, and folder IDs. folder IDs were used to expand and collapse the tree
  let folderId = commands[0].folderId;

  //-------------------------------------Below: for arguments display ------------------------------------------------------

  //--------------levels tab-----------------------------

  const useStyles = makeStyles({
    TabList: {
      //This is not a proper way, will change later......
      width: "350px",
    },
    TabPanel: {
      padding: "10px 5px 20px 0",
    },
  });
  const classes = useStyles();

  //Handle argument level tab changes
  const [tabValue, setTabValue] = useState("0");
  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  //This function find the levels of arguments
  function FindLevels(argumentsList) {
    let levels = [];
    let levelsTemp = [];
    for (let i = 0; i < argumentsList.length; i++) {
      if (argumentsList[i].level) {
        levelsTemp.push(argumentsList[i].level);
      }
    }

    levels = [...new Set(levelsTemp)];

    return levels;
  }

  //----------------aguments tabpanel--------------------

  //This function find an argument's level, display arguments
  function DisplayArguments({ argumentsList }) {
    let levels = FindLevels(argumentsList);
    let allArgumentListDisplay = [];
    let k = 0;

    //Store arguments' original index
    for (let i = 0; i < argumentsList.length; i++) {
      Object.assign(argumentsList[i], { argIndex: i });
    }

    for (let j = 0; j < levels.length; j++) {
      let argumentListDisplay = [];
      for (let p = 0; p < argumentsList.length; p++) {
        if (argumentsList[p].level === levels[j]) {
          if (argumentsList[p].arg_type === "select") {
            //Set default value, "argValues" will store user entered parameters or default value, submit to database
            //If there is no default value, store empty string "" or false if it is bool type
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] = argumentsList[p].options[0].arg
                ? argumentsList[p].options[0].arg
                : "";
            }
            argumentListDisplay.push(
              <SelectionType argument={argumentsList[p]} i={k} />
            );

            originalIndex[k] = argumentsList[p].argIndex;

            k = k + 1;
          } else if (argumentsList[p].arg_type === "file_path") {
            //Save the index of file path type which will be used to find job parents
            filePathIndex.push(k);

            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] = argumentsList[p].default
                ? argumentsList[p].default
                : "";
            }
            argumentListDisplay.push(
              <FilePathType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "folder") {
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] = argumentsList[p].default
                ? argumentsList[p].default
                : "";
            }
            argumentListDisplay.push(
              <FolderType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "output_path") {
            argValues[k] = outputPath
              ? outputPath +
                (argumentsList[p].suffix ? argumentsList[p].suffix : "")
              : "";
            argumentListDisplay.push(
              <OutputPathType argument={argumentsList[p]} i={k} />
            );

            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "output_path_fn") {
            argValues[k] = outputPath
              ? outputPath +
                (argumentsList[p].suffix ? argumentsList[p].suffix : "")
              : "";
            argumentListDisplay.push(
              <OutputPathFnType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "file") {
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] = argumentsList[p].default
                ? argumentsList[p].default
                : "";
            }
            argumentListDisplay.push(
              <FileType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "bool") {
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] = argumentsList[p].default
                ? argumentsList[p].default
                : false;
            }
            argumentListDisplay.push(
              <BoolType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "str") {
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] = argumentsList[p].default
                ? argumentsList[p].default
                : "";
            }
            argumentListDisplay.push(
              <StrType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "float") {
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] =
                argumentsList[p].default ||
                argumentsList[p].default === 0 ||
                argumentsList[p].default === 0.0
                  ? argumentsList[p].default
                  : "";
            }
            argumentListDisplay.push(
              <FloatType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else if (argumentsList[p].arg_type === "int") {
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] =
                argumentsList[p].default || argumentsList[p].default === 0
                  ? argumentsList[p].default
                  : "";
            }
            argumentListDisplay.push(
              <IntType argument={argumentsList[p]} i={k} />
            );
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          } else {
            argumentListDisplay.push(
              `<span>Unknown argument type: ${argumentsList[p].arg_type}</span>`
            );
            if (argValues[k] === null || argValues[k] === undefined) {
              argValues[k] = argumentsList[p].default
                ? argumentsList[p].default
                : "";
            }
            originalIndex[k] = argumentsList[p].argIndex;
            k = k + 1;
          }
        }
      }

      allArgumentListDisplay.push(
        <Stack sx={{ padding: "5px", marginTop: "8px" }} spacing={2}>
          {" "}
          {argumentListDisplay.map((arg, i) => (
            <span key={i}>{arg}</span>
          ))}{" "}
        </Stack>
      );
    }

    return allArgumentListDisplay.map((tabPanelItem, i) => (
      <TabPanel value={i.toString()} key={i} className={classes.TabPanel}>
        {tabPanelItem}
      </TabPanel>
    ));
  }

  //------------------------------Below: 10 argument types-----------------------------------------------------------------------------------------------

  function OutputPathType({ argument, i }) {
    argValues[i] = outputPath
      ? outputPath.data + (argument.suffix ? argument.suffix : "")
      : "";
    return outputPath !== undefined ? ( // don't show circle when disableALl
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip
          title={
            <p
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {argument.description || ""}
            </p>
          }
          placement="left"
        >
          <TextField
            sx={{ width: "100%" }}
            label={argument.name}
            value={argValues[i]}
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled // always disabled, when need to change, use the disableAll
            id={argument.name}
          />
        </Tooltip>
        <IconButton
          aria-label="reset field"
          disabled // always disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    ) : (
      <CircularProgress color="inherit" size={20} />
    );
  }

  function OutputPathFnType({ argument, i }) {
    let argumentDefaultValue = outputPath
      ? outputPath + (argument.suffix ? argument.suffix : "")
      : "";

    //When fetch data form server,Session was used to store user entered values,
    if (sessionStorage.getItem("outputPath" + i.toString())) {
      argumentDefaultValue = sessionStorage.getItem(
        "outputPath" + i.toString()
      );
    }

    const [outputPathFnValue, setOutputPathFnValue] =
      useState(argumentDefaultValue);
    argValues[i] = outputPathFnValue ? outputPathFnValue : "";

    return outputPath !== undefined ? (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip
          title={
            <p
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {argument.description || ""}
            </p>
          }
          placement="left"
        >
          <TextField
            sx={{ width: "100%" }}
            label={argument.name}
            size="small"
            value={outputPathFnValue ? outputPathFnValue : ""}
            variant="outlined"
            disabled={argument.disabled}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setOutputPathFnValue(e.target.value);
              argValues[i] = outputPathFnValue ? outputPathFnValue : "";
              sessionStorage.setItem(
                "outputPath" + i.toString(),
                e.target.value
              );
            }}
          />
        </Tooltip>

        <IconButton
          aria-label="reset field"
          disabled={argument.disabled}
          onClick={() => {
            setOutputPathFnValue(
              outputPath
                ? outputPath + (argument.suffix ? argument.suffix : "")
                : ""
            );
            sessionStorage.setItem(
              "outputPath" + i.toString(),
              outputPath
                ? outputPath + (argument.suffix ? argument.suffix : "")
                : ""
            );
          }}
          sx={{ cursor: "pointer" }}
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    ) : (
      <CircularProgress color="inherit" size={20} />
    );
  }

  function FilePathType({ argument, i }) {
    let argumentDefaultValue = argument.default ? argument.default : "";

    const [filePathValue, setFilePathValue] = useState(
      removeQuotes(argValues[i])
    );

    //Handle file path modal open and close
    const [filePathOpen, setFilePathOpen] = useState(false);
    const [filePaths, setFilePaths] = useState(undefined);

    useEffect(() => {
      if (currProjectInfo !== undefined) {
        fetchFilePaths();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currProjectInfo, commands, commandIdx]);

    async function fetchFilePaths() {
      // setFilePaths(undefined);
      try {
        let userId = localStorage.getItem("userId");

        let p1 = (await axios.post("/api/job/inputFiles", { userId })).data;
        // change input file paths to rel paths
        p1 = p1.map(
          (path) => "../.." + path.substring(path.indexOf("/inputFiles"))
        );

        const p2 = (
          await axios.post("/api/job/allJobFiles", {
            projectId: currProjectInfo.projectId,
          })
        ).data;
        setFilePaths(p1.concat(p2.data));
      } catch (e) {}
    }

    function wrapInQuotes(s) {
      return s && `"${s}"`;
    }

    function removeQuotes(s) {
      return s && s.replaceAll('"', "");
    }

    function handleClickOpen() {
      fetchFilePaths();
      setFilePathOpen(true);
    }
    const handleClose = () => {
      setFilePathOpen(false);
    };

    let filteredFilePth = filePaths;
    if (argument.must_contain) {
      if (filePaths) {
        filteredFilePth = filePaths.filter((path) =>
          path?.includes(argument.must_contain)
        );
      }
    }

    return filePaths !== undefined ? (
      <div>
        <Stack direction="row" spacing={1}>
          <Tooltip
            title={
              <p
                style={{
                  fontSize: 12,
                  lineHeight: 1.5,
                  marginTop: 3,
                  marginBottom: 3,
                }}
              >
                {argument.description || ""}
              </p>
            }
            placement="left"
          >
            <TextField
              id={argument.name}
              size="small"
              fullWidth
              inputProps={{ readOnly: true }}
              placeholder={argument.name}
              value={argValues[i] ? removeQuotes(argValues[i]) : ""}
            ></TextField>
          </Tooltip>

          <Tooltip
            title={
              <p
                style={{
                  fontSize: 12,
                  lineHeight: 1.5,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                Select
              </p>
            }
            placement="right"
          >
            <span>
              <IconButton
                onClick={handleClickOpen}
                disabled={argument.disabled}
                sx={{ cursor: "pointer", marginLeft: "8px" }}
              >
                <FolderOpenIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
        <Dialog
          open={filePathOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"lg"}
        >
          <DialogTitle id="form-dialog-title">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginRight: "5px" }}
            >
              {argument.description}
              <IconButton onClick={handleClose} sx={{ cursor: "pointer" }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>

          <DialogContent>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ marginTop: 2 }}
            >
              <Autocomplete
                freeSolo
                size="small"
                sx={{ width: 800 }}
                value={filePathValue ? filePathValue : ""}
                disabled={argument.disabled}
                renderOption={(props, option) => (
                  <span
                    {...props}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "fit-content",
                    }}
                  >
                    {option}
                  </span>
                )}
                onChange={(e, v) => {
                  if (v !== null || v !== undefined) {
                    setFilePathValue(v);
                    setFilePathValue(v);
                    argValues[i] = wrapInQuotes(v);
                    argValues[i] = wrapInQuotes(v);
                  } else {
                    setFilePathValue("");
                    argValues[i] = "";
                  }
                }}
                options={filteredFilePth || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={argument.name}
                    onChange={(e) => {
                      setFilePathValue(e.target.value);
                      argValues[i] = wrapInQuotes(e.target.value);
                    }}
                  />
                )}
              />

              <Tooltip title="Reset" placement="right">
                <span>
                  <IconButton
                    aria-label="reset field"
                    onClick={() => {
                      setFilePathValue(argumentDefaultValue);
                      document.querySelector(`#${argument.name}`).value = "";
                      argValues[i] = wrapInQuotes(argumentDefaultValue);
                    }}
                    disabled={argument.disabled}
                    sx={{ cursor: "pointer" }}
                  >
                    <ReplayIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </DialogContent>
          <Box sx={{ width: "100%", textAlign: "center", mb: "10px" }}>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Confirm
            </Button>
          </Box>
        </Dialog>
      </div>
    ) : (
      <CircularProgress color="inherit" size={20} />
    );
  }

  function FolderType({ argument, i }) {
    return <Fragment>TODO folder component</Fragment>;
  }

  function FileType({ argument, i }) {
    return <Fragment>TODO file component</Fragment>;
  }

  function BoolType({ argument, i }) {
    let argumentDefaultValue = argument.default ? argument.default : false;

    const [boolValue, setBoolValue] = useState(argValues[i]);

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip
          title={
            <p
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {argument.description || ""}
            </p>
          }
          placement="left"
        >
          <FormControlLabel
            sx={{ width: "100%" }}
            label={argument.name}
            disabled={argument.disabled}
            control={
              <Checkbox
                checked={boolValue}
                size="small"
                onChange={(e) => {
                  setBoolValue(e.target.checked);
                  argValues[i] = e.target.checked;
                }}
              />
            }
          />
        </Tooltip>

        <IconButton
          aria-label="reset field"
          onClick={() => {
            setBoolValue(argumentDefaultValue);
            argValues[i] = argumentDefaultValue;
          }}
          disabled={argument.disabled}
          sx={{ cursor: "pointer" }}
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );
  }

  function StrType({ argument, i }) {
    let argumentDefaultValue = argument.default ? argument.default : "";

    const [strValue, setStrValue] = useState(argValues[i]);

    // check if argument string contains spaces
    const hasError = strValue.includes(" ");

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip
          title={
            <p
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {argument.description || ""}
            </p>
          }
          placement="left"
        >
          <TextField
            error={hasError}
            helperText={hasError && "Spaces are not allowed in str field type"}
            sx={{ width: "100%" }}
            label={argument.name}
            size="small"
            value={strValue ? strValue : ""}
            variant="outlined"
            disabled={argument.disabled}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setStrValue(e.target.value);
              argValues[i] = e.target.value;
            }}
          />
        </Tooltip>

        <IconButton
          aria-label="reset field"
          onClick={() => {
            setStrValue(argumentDefaultValue);
            argValues[i] = argumentDefaultValue;
          }}
          disabled={argument.disabled}
          sx={{ cursor: "pointer" }}
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );
  }

  function FloatType({ argument, i }) {
    let argumentDefaultValue =
      argument.default || argument.default === 0 || argument.default === 0.0
        ? argument.default
        : "";

    const [floatValue, setFloatValue] = useState(argValues[i]);
    const [error, setError] = useState("");

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip
          title={
            <p
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {argument.description || ""}
            </p>
          }
          placement="left"
        >
          <TextField
            error={!!error}
            helperText={error}
            label={argument.name}
            sx={{ width: "100%" }}
            size="small"
            value={floatValue}
            variant="outlined"
            disabled={argument.disabled}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              if (
                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?]/.test(e.target.value)
              ) {
                setError("Please enter a decimal");
                setFloatValue("");
              } else {
                setError("");
                setFloatValue(e.target.value);
                argValues[i] = e.target.value;
              }
            }}
          />
        </Tooltip>
        <IconButton
          aria-label="reset field"
          onClick={() => {
            setFloatValue(argumentDefaultValue);
            argValues[i] = argumentDefaultValue;
          }}
          disabled={argument.disabled}
          sx={{ cursor: "pointer" }}
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );
  }

  function IntType({ argument, i }) {
    let argumentDefaultValue =
      argument.default || argument.default === 0 ? argument.default : "";

    const [intValue, setIntValue] = useState(parseInt(argValues[i]));
    const [error, setError] = useState("");

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip
          title={
            <p
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {argument.description || ""}
            </p>
          }
          placement="left"
        >
          <TextField
            sx={{ width: "100%" }}
            helperText={error}
            type="number"
            label={argument.name}
            size="small"
            variant="outlined"
            value={isNaN(intValue) ? "" : intValue}
            InputLabelProps={{ shrink: true }}
            disabled={argument.disabled}
            onChange={(e) => {
              if (isNaN(parseInt(e.target.value))) {
                setError("Please enter an integer");
                setIntValue("");
              } else {
                setError("");
                setIntValue(parseInt(e.target.value));
                argValues[i] = parseInt(e.target.value);
              }
            }}
          />
        </Tooltip>
        <IconButton
          aria-label="reset field"
          onClick={() => {
            setIntValue(argumentDefaultValue);
            argValues[i] = argumentDefaultValue;
          }}
          disabled={argument.disabled}
          sx={{ cursor: "pointer" }}
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );
  }

  function SelectionType({ argument, i }) {
    let argumentDefaultValue = argument.options[0].arg
      ? argument.options[0].arg
      : "";

    const [optionValue, setOptionValue] = useState(argValues[i]);

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id={argument.name} shrink>
            {argument.name}
          </InputLabel>
          <Tooltip
            title={
              argument.description ? (
                <p
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    marginTop: 3,
                    marginBottom: 3,
                  }}
                >
                  {argument.description}
                </p>
              ) : (
                ""
              )
            }
            placement="left"
          >
            <Select
              disabled={argument.disabled}
              label="{argument.name}"
              notched
              id={argument.name}
              value={optionValue}
              size="small"
              onChange={(e) => {
                setOptionValue(e.target.value);
                argValues[i] = e.target.value;
              }}
            >
              {argument.options.map((opt, i) => (
                <MenuItem key={i} value={opt.arg} title={opt.description}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
        </FormControl>
        <IconButton
          aria-label="reset field"
          onClick={() => {
            setOptionValue(argumentDefaultValue);
            argValues[i] = argumentDefaultValue;
          }}
          disabled={argument.disabled}
          sx={{ cursor: "pointer" }}
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );
  }

  //---------------------------------------------------------------return-----------------------------------------------------------------------------------------------

  // TODO disable things when contacting backend for requests
  return (
    <div>
      <FormControl fullWidth>
        <Box
          sx={{ fontFamily: "default", m: 0.5, fontSize: 13, color: "#808080" }}
        >
          Please search or browse the list to select your tool
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          <Autocomplete
            id="inputTools"
            size="small"
            sx={{ width: "100%" }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={commands || []}
            getOptionLabel={(option) => option.custom_name}
            value={commands[commandIdx] || null}
            onChange={(e, v) => {
              if (v !== null) {
                changeCommand(commands.findIndex((cmd) => cmd === v));
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Tooltip
            title={
              <p style={{ fontSize: 12, marginTop: 3, marginBottom: 3 }}>
                Open the list of all tools{" "}
              </p>
            }
            placement="top"
          >
            <IconButton component="label" onClick={handleOpen}>
              <ViewList />
            </IconButton>
          </Tooltip>
        </Stack>

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
          <Box
          // sx={{
          //   position: "absolute",
          //   top: "50%",
          //   left: "50%",
          //   transform: "translate(-50%, -50%)",
          //   width: 930,
          //   height: 700,
          //   bgcolor: "background.paper",
          //   boxShadow: 24,
          //   p: 1,
          //   borderRadius: 1,
          // }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{ width: "95%", textAlign: "left", paddingTop: "10px" }}
              >
                <Button
                  onClick={handleExpandClick}
                  variant="text"
                  sx={{ width: 150 }}
                >
                  {expanded.length === 0 ? "Expand all" : "Collapse all"}
                </Button>
              </div>

              <div style={{ width: "5%", textAlign: "right" }}>
                {" "}
                <IconButton onClick={handleClose} sx={{ cursor: "pointer" }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <Stack direction="row">
              <Box
                sx={{
                  fontFamily: "default",
                  m: 0.5,
                  fontSize: 15,
                  width: 400,
                  height: 590,
                  overflowY: "auto",
                  margin: "15px  30px 20px 30px",
                }}
              >
                <p id="toolDes" style={{ lineHeight: "1.5" }}></p>
              </Box>
            </Stack>
          </Box>
          <DialogActions>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ padding: "0px 15px 15px 0" }}
            >
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="confirmName"
                variant="contained"
                onClick={getToolName}
                disabled={disablesState}
              >
                Confirm
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </FormControl>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ padding: "10px 13px 0 0" }}
      >
        <Tooltip title={"Reset all"} placement="left">
          <div>
            <IconButton
              variant="outlined"
              onClick={() => setDefaults()}
              size="large"
            >
              <SettingsBackupRestoreIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Stack>

      <div>
        <Paper sx={{ marginTop: "5px" }}>
          <Stack sx={{ padding: "5px", marginTop: "8px" }} spacing={1}>
            <TabContext value={tabValue}>
              {" "}
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  id="s"
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  className={classes.TabList}
                >
                  {FindLevels(commands[commandIdx].arguments).map(
                    (tabItem, i) => (
                      <Tab
                        label={tabItem}
                        value={i.toString()}
                        key={i}
                        className={classes.Tab}
                      />
                    )
                  )}
                </TabList>
              </Box>
              <DisplayArguments
                argumentsList={commands[commandIdx].arguments}
              />
            </TabContext>
          </Stack>
        </Paper>
      </div>
    </div>
  );
}
