import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import * as React from "react";

import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";

export default function ProjectArchive({
  setProject,
  fetchProjects,
  projectId,
  disabledBtn,
}) {
  const [openArchiveConf, setOpenArchiveConf] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMes, setSnackbarMes] = useState("");
  const [archiveDestination, setArchiveDestination] = useState("");

  let error = undefined;
  if (archiveDestination === "") {
    error = "Destination can't be blank";
  }

  const handleArchiveConfClickOpen = () => {
    setOpenArchiveConf(true);
  };

  const handleArchiveConfClose = () => {
    setOpenArchiveConf(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbar = (res) => {
    setSnackbarOpen(true);
    if (res.data.code === 0) {
      setSnackbarMes("Archive project success");
    } else {
      setSnackbarMes("Fail to archive project");
    }
  };

  async function handleArchiveProject(projectId) {
    setProject(undefined);
    setArchiveDestination("");
    const res = await axios.post("/api/project/archiveproject", {
      projectId,
      archiveDestination,
    });
    setSnackbarOpen(true);
    handleSnackbar(res);
    handleArchiveConfClose();
    fetchProjects();
  }
  return (
    <>
      <Button
        sx={{ marginLeft: "1.5rem" }}
        variant="outlined"
        onClick={handleArchiveConfClickOpen}
        startIcon={<ArchiveOutlinedIcon />}
        disabled={disabledBtn || localStorage.getItem("userId") === null}
      >
        Archive
      </Button>
      <Dialog
        open={openArchiveConf}
        onClose={handleArchiveConfClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to archive the project?"}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={!!error}
            helperText={error}
            autoFocus
            margin="normal"
            id="name"
            label="Destination"
            fullWidth
            onChange={(e) => setArchiveDestination(e.target.value)}
            value={archiveDestination}
          />
          <DialogContentText id="alert-dialog-description">
            Archiving the project will result removing all job data from the
            database and the corresponding files and folders.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!!error}
            onClick={() => handleArchiveProject(projectId)}
          >
            Archive
          </Button>
          <Button onClick={handleArchiveConfClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMes}
      />
    </>
  );
}
