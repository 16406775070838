import { Outlet, useNavigate } from "react-router-dom";
import { AppBar, Button, Stack, Toolbar, Box, Typography } from "@mui/material";
import logo from "./../../images/default/cryohub_logo.png";
import UserButton from "./../../components/UserButton";

export default function MainLayout() {
  return (
    <>
      <AppTopBar />
      <Box sx={{ mt: "60px" }}></Box>
      <Outlet />
      <AppFooter />
    </>
  );
}

function AppTopBar() {
  const navigate = useNavigate();

  function goHomePage() {
    navigate("/main");
  }

  return (
    <AppBar position="fixed" elevation={1} color="inherit">
      <Toolbar disableGutters variant="dense">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", pl: "10px", pr: "10px" }}
        >
          <img
            height="50px"
            src={logo}
            alt="cryohub logo"
            onClick={goHomePage}
            style={{ cursor: "pointer" }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Button variant="outlined" disabled="true">
              Admin
            </Button>
            <Button
              variant="outlined"
              href="https://arkadien.notion.site/Welcome-to-cryoHub-758eb0bdd10a4426ab2c71b6cad6e13f"
              target="_blank"
            >
              Help
            </Button>
            <UserButton />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

function AppFooter() {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: "5px" }}
      >
        {"Copyright © "}
        Cryohub
        {" " + new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}
