import React from 'react'
import './pages.css'

import { Fragment, useState } from "react";

import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Select,
  Stack,
  Tab,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";

import ReplayIcon from "@mui/icons-material/Replay";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import Box from '@mui/material/Box';
import ViewList from '@mui/icons-material/ViewList';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import MenuItem from '@mui/material/MenuItem';






export default function Inputs({ commands, data }) {

  return (

    <Paper
      elevation={2} sx={{ margin: "auto", padding: "5px", width: "700px" }}
    >
      <CommandRunnerReadOnly commands={commands} inputData={data} />
    </Paper>
  );

}



//This function was used to display each job card's arguments information
function CommandRunnerReadOnly({
  commands,
  inputData,
}) {

  const commandIdx =  getCommandDefault(inputData);

  //Used to get command arguments' original index
  const originalIndex = [];

  //The arguments fetched from database
  const argValuesSubmitted = inputData?  JSON.parse(inputData["commandArgs"]) : [];
  //The arguments need to be displayed
  const argValues =  [];


  function getCommandDefault(inputData) {
    
    if (inputData !== undefined) {
      // using the commandName to find its cmdIndex, used for filling the command from inputData as default
      for (let i in commands) {
        if (commands[i]["custom_name"] === inputData["customName"])
          return parseInt(i, 10);
      }
    }
    return 0;
  }




  //-------------------------------------Below: for arguments display ------------------------------------------------------





  //--------------levels tab-----------------------------

  const useStyles = makeStyles({

    TabList: {
      maxWidth: "680px"
    },
    TabPanel: {
      padding: '10px 5px 20px 0',
    }

  });
  const classes = useStyles();


  //Handle argument level tab changes
  const [tabValue, setTabValue] = useState("0");
  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  }

  //This function find the levels of arguments
  function FindLevels(argumentsList) {
    let levels = [];

    let levelsTemp = [];
    for (let i = 0; i < argumentsList.length; i++) {
      if (argumentsList[i].level) {
        levelsTemp.push(argumentsList[i].level);
      }
    }

    levels = [...new Set(levelsTemp)];

    return levels;
  }


  //----------------aguments tabpanel--------------------



  //This function find an argument's level, display arguments
  function DisplayArguments({ argumentsList }) {

    let levels = FindLevels(argumentsList);
    let allArgumentListDisplay = [];
    let k = 0;

    for(let i = 0; i < argumentsList.length; i++)
    {
      Object.assign(argumentsList[i], {argIndex: i})
    }

    for (let j = 0; j < levels.length; j++) {
      let argumentListDisplay = [];
      for (let p = 0; p < argumentsList.length; p++) {

        if (argumentsList[p].level === levels[j]) {

          if (argumentsList[p].arg_type === "select") {

            argumentListDisplay.push(<SelectionType argument={argumentsList[p]} i={k} />)
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "file_path") {

            argumentListDisplay.push(<FilePathType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "folder") {

            argumentListDisplay.push(<FolderType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "output_path") {

            argumentListDisplay.push(<OutputPathType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "output_path_fn") {

            argumentListDisplay.push(<OutputPathFnType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "file") {

            argumentListDisplay.push(<FileType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "bool") {

            argumentListDisplay.push(<BoolType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "str") {

            argumentListDisplay.push(<StrType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "float") {

            argumentListDisplay.push(<FloatType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else if (argumentsList[p].arg_type === "int") {

            argumentListDisplay.push(<IntType argument={argumentsList[p]} i={k} />);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
          else {
            argumentListDisplay.push(`<span>Unknown arg type: ${argumentsList[p].arg_type}</span>`);
            originalIndex[k]= argumentsList[p].argIndex;
            k = k + 1;
          }
        }
      }

  


      allArgumentListDisplay.push(<Stack sx={{ padding: "5px", marginTop: "8px" }} spacing={2}> {argumentListDisplay.map((arg, i) => (<span key={i}>{arg}</span>))} </Stack>);
    }

    // Find the sequence of arguments
    for(let i = 0; i < argumentsList.length; i++)
    {
      argValues[i] = argValuesSubmitted[originalIndex[i]];
    }

    return allArgumentListDisplay.map((tabPanelItem, i) => (<TabPanel value={i.toString()} key={i} className={classes.TabPanel}>{tabPanelItem}</TabPanel>));
  }





  //------------------------------Below: 10 argument types, for read only-----------------------------------------------------------------------------------------------



  function OutputPathType({ argument, i }) {

    return (

      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip title={<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description || ""}</p>} placement="left">
          <TextField
            sx={{ width: "100%" }}
            label={argument.name}
            defaultValue={argValues[i]}
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled
            id={argument.name}
          />
        </Tooltip>
        <IconButton
          aria-label="reset field"
          disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );
  }


  function OutputPathFnType({ argument, i }) {

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip title={<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description || ""}</p>} placement="left">
          <TextField
            sx={{ width: "100%" }}
            label={argument.name}
            size="small"
            defaultValue={argValues[i]}
            variant="outlined"
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Tooltip>

        <IconButton
          aria-label="reset field"
          disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>

    );
  }


  function FilePathType({ argument, i }) {

    return (
      <div>
        <Stack
          direction="row"
          spacing={1}
        >
          <Tooltip title={<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description || ""}</p>} placement="bottom">
            <TextField id={argument.name} size="small"
              fullWidth disabled placeholder={argument.name} defaultValue={argValues[i]}></TextField>
          </Tooltip>
          <span>
            <IconButton sx={{ marginLeft: "8px" }}
              disabled
            >
              <FolderOpenIcon />
            </IconButton></span>
        </Stack>
      </div>

    );

  }



  function FolderType({ argument, i }) {
    return <Fragment>TODO folder component</Fragment>;

  }


  function FileType({ argument, i }) {
    return <Fragment>TODO file component</Fragment>;
  }


  function BoolType({ argument, i }) {

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip title={<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description || ""}</p>} placement="left">
          <FormControlLabel
            sx={{ width: "100%" }}
            label={argument.name}
            disabled
            control={
              <Checkbox
                checked={argValues[i]}
                size="small"
              />
            }
          />
        </Tooltip>

        <IconButton
          aria-label="reset field"
          disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );
  }


  function StrType({ argument, i }) {
    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip title={<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description || ""}</p>} placement="left">
          <TextField
            sx={{ width: "100%" }}
            label={argument.name}
            size="small"
            defaultValue={argValues[i]}
            variant="outlined"
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Tooltip>

        <IconButton
          aria-label="reset field"
          disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );

  }


  function FloatType({ argument, i }) {

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip title={<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description || ""}</p>} placement="left">
          <TextField
            label={argument.name}
            sx={{ width: "100%" }}
            size="small"
            defaultValue={argValues[i]}
            variant="outlined"
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Tooltip>
        <IconButton
          aria-label="reset field"
          disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );

  }



  function IntType({ argument, i }) {

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip title={<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description || ""}</p>} placement="left">
          <TextField
            sx={{ width: "100%" }}
            type="number"
            label={argument.name}
            size="small"
            variant="outlined"
            defaultValue={argValues[i]}
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Tooltip>
        <IconButton
          aria-label="reset field"
          disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );

  }


  function SelectionType({ argument, i }) {

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id={argument.name} shrink>{argument.name}</InputLabel>
          <Tooltip title={argument.description ? (<p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 3, marginBottom: 3 }}>{argument.description}</p>) : ""} placement="left">
            <Select
              disabled
              label="{argument.name}"
              notched
              id={argument.name}
              defaultValue={argValues[i]}
              size="small"
            >
              {argument.options.map((opt, i) => (
                <MenuItem key={i} value={opt.arg} title={opt.description}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>

          </Tooltip>
        </FormControl>
        <IconButton
          aria-label="reset field"
          disabled
        >
          <ReplayIcon />
        </IconButton>
      </Stack>
    );

  }


  //---------------------------------------------------------------return-----------------------------------------------------------------------------------------------


  // TODO disable things when contacting backend for requests
  return (
    <div>
      <FormControl fullWidth>
        <Box sx={{ fontFamily: "default", m: 0.5, fontSize: 13, color: "#808080", paddingTop: "10px" }}>
          Please search or browse the list to select your tool
        </Box>
        <Stack direction="row" justifyContent="flex-end" >
          <Autocomplete
            id="inputTools"
            size="small"
            sx={{ width: '100%' }}
            disabled
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={commands || []}
            getOptionLabel={(option) =>
              option.custom_name
                ? option.custom_name
                : option.name
                  ? option.name
                  : ""
            }
            value={commands[commandIdx] || null}

            renderInput={(params) => <TextField {...params} />}
          />
          <IconButton component="label" disabled>
            <ViewList />
          </IconButton>

        </Stack>
      </FormControl>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ padding: "10px 25px 0 0" }}
      >
        <div>
          <IconButton
            variant="outlined"
            size="large"
            disabled
          >
            <SettingsBackupRestoreIcon />
          </IconButton>
        </div>

        <div>
          <IconButton
            variant="outlined"
            size="large"
            disabled
          >
            <RefreshIcon />
          </IconButton>
        </div>

      </Stack>

      <div
        style={{
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <Stack sx={{ padding: "5px", marginTop: "8px" }} spacing={1} >
          <TabContext value={tabValue} >  <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
            <TabList id="s" onChange={handleChange} variant="scrollable" scrollButtons="auto" className={classes.TabList}  >
              {FindLevels(commands[commandIdx].arguments).map((tabItem, i) => (<Tab label={tabItem} value={i.toString()} key={i} className={classes.Tab} />))}
            </TabList></Box>
            <DisplayArguments argumentsList={commands[commandIdx].arguments} />
          </TabContext>
          <Button
            sx={{ width: "100%", marginTop: "10px" }}
            variant="contained"
            disabled
          >
            Run
          </Button>
        </Stack>

      </div></div>
  );
}








