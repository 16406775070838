import { load } from "js-yaml";
import { Alert, AlertTitle } from "@mui/material";
import PreviewCommandRunner from "./PreviewCommandRunner";

export default function CmdPreview(props) {
  if (!props.ymalRawData) return <WarnEmptyComponent />;

  // load the yaml file
  let loadedYmalRawData;
  try {
    loadedYmalRawData = load(props.ymalRawData);
    // check if not a object throw error
    if (typeof loadedYmalRawData !== "object") throw new Error("Not a Object");
  } catch (e) {
    return <ErrorComponent e={e} />;
  }

  try {
    return <PreviewCommandRunner commands={[loadedYmalRawData]} />;
  } catch (error) {
    return <div>error</div>;
  }
}

function ErrorComponent({ e }) {
  return (
    <>
      <Alert severity="error">
        <AlertTitle>An Error Occured When Loading Yaml File</AlertTitle>
        {e.message}
        <br />
        <br />
        Please check the format and strcture of the yaml file.
      </Alert>
    </>
  );
}

function WarnEmptyComponent() {
  return (
    <>
      <Alert severity="warning">
        <AlertTitle>Empty Yaml File</AlertTitle>
        Please enter some commands in the editor.
      </Alert>
    </>
  );
}
