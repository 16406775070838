import { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Paper,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Cookies from "js-cookie";
import { isEmail } from "../utils/strValidator";

export default function Login() {
  function Form() {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [userPassword, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [simpleDialogOpen, setSimpleDialogOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    function handleSimpleDialogOpen(flag) {
      setSimpleDialogOpen(flag);
    }

    function regForm() {
      let validEmail = false,
        validPassword = false;
      if (!email) {
        setEmailError("Email is required");
      } else if (!isEmail(email)) {
        setEmailError("Email must be valid");
      } else {
        setEmailError("");
        validEmail = true;
      }

      if (!userPassword) {
        setPasswordError("Password is required");
      } else {
        setPasswordError("");
        validPassword = true;
      }
      return validEmail && validPassword;
    }

    //Change (remember me for 7 days) checkbox state
    const [checkedBox, setCheckedBox] = useState(false);
    const handleRememberMe = () => {
      setCheckedBox(!checkedBox);
    };

    async function login() {
      if (regForm()) {
        const form = {
          userName: email,
          userPassword,
        };
        const ps = await axios.post("/api/user/login", form);
        setSelectedValue(ps.data.msg);
        handleSimpleDialogOpen(true);
        if (ps.data.code === 0) {
          localStorage.setItem("userId", ps.data.data.userId);

          //If (remember me for 7 days) checkbox is checked, create a cookie
          if (checkedBox) {
            Cookies.set("userId", ps.data.data.userId, { expires: 7 });
          }

          setTimeout(() => {
            window.location.href = "/main";
          }, 500);
        }
      }
    }

    return (
      <Paper
        sx={{
          width: "300px",
          height: "300px",
          display: "flex",
          padding: "20px",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Sign In
        </Typography>
        <TextField
          error={!!emailError}
          helperText={emailError}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          error={!!passwordError}
          helperText={passwordError}
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          value={userPassword}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              size="small"
              onChange={handleRememberMe}
            />
          }
          label="Remember me for 7 days"
        />
        <Button variant="contained" onClick={login}>
          Sign in
        </Button>
        <Snackbar
          open={simpleDialogOpen}
          autoHideDuration={6000}
          onClose={() => {
            handleSimpleDialogOpen(false);
          }}
          message={selectedValue}
        />
      </Paper>
    );
  }
  function CallOut() {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography component="div">
          {" "}
          New to Here? <Link href="/signup">Create an account</Link>
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: "100px auto",
        padding: "30px",
        width: "300px",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Form></Form>
      <CallOut></CallOut>
    </Box>
  );
}
