const placeholder = `# 11/18/2022
name: Import
custom_name: Import (RELION 4.0)
preprocess: module purge && module load relion-gpu
command: relion_import
description: Import movies, micrographs, coordinates, half maps or particle STAR files. 
# postprocess: 

arguments:
# General options
  - name: Import Data Type
    level: basic
    arg_type: select
    arg:
    default: --do_movies
    options:
      - name: Movies
        arg: --do_movies
        description: Import movies

      - name: Micrographs
        arg: --do_micrographs
        description: Import micrographs

      - name: Coordinates
        arg: --do_coordinates
        description: Import coordinates

      - name: Half maps
        arg: --do_halfmaps
        description: Import unfiltered half maps

      - name: Particle STAR files
        arg: --do_particles
        description: Import particle STAR files

  - name: Input
    arg: --i
    level: basic
    arg_type: file_path
    description: Input (wildcard) filename

  - name: Output directory
    arg: --odir
    level: basic
    arg_type: output_path
    description: Output directory (e.g. "Import/job001/"

  - name: Output file name
    arg: --ofile
    level: basic
    arg_type: str
    description: Output file name (e.g. "movies.star"

  - name: Particles optics group name
    arg: --particles_optics_group_name
    level: advanced
    arg_type: str
    description: Rename optics group for all imported particles (e.g. "opticsGroupLMBjan2019"

  - name: Do other
    arg: --do_other
    level: advanced
    arg_type: bool
    description: Import anything else
    default: false

# Specific options for movies or micrographs
  - name: Optics group name
    arg: --optics_group_name
    level: advanced
    arg_type: str
    description: Name for this optics group
    default: opticsGroup1

  - name: Optics group mtf
    arg: --optics_group_mtf
    level: advanced
    arg_type: str
    description: Name for this optics group's MTF

  - name: Pixel size
    arg: --angpix
    level: basic
    arg_type: float
    description: Pixel size (Angstrom)
    default: 1.5

  - name: Voltage
    arg: --kV
    level: basic
    arg_type: int
    description: Voltage (kV)
    default: 300

  - name: Spherical aberration
    arg: --Cs
    level: basic
    arg_type: float
    description: Spherical aberration (mm)
    default: 0.0

  - name: Amplitude contrast
    arg: --Q0
    level: basic
    arg_type: float
    description: Amplitude contrast
    default: 0.1

  - name: Beam tilt x
    arg: --beamtilt_x
    level: basic
    arg_type: float
    description: Beam tilt (X; mrad)
    default: 0.0

  - name: Beam tilt y
    arg: --beamtilt_y
    level: basic
    arg_type: float
    description: Beam tilt (Y; mrad)
    default: 0.0

  - name: Continue
    arg: --continue
    level: advanced
    arg_type: bool
    description: Continue and old run, add more files to the same import directory
    default: false

  # - name: Version
  #   arg: --version
  #   level: advanced
  #   arg_type: '#TODO'
  #   description: Print RELION version and exit

`;

export default placeholder;
