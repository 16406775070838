import * as React from "react";
import {
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    Button,
    Tabs,
    Tab,
    TextField
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
export default function ProjectFile({}) {
    let id = 0;

    function createData(name, calories, fat, carbs, protein) {
        id += 1;
        return {id, name, calories, fat, carbs, protein};
    }

    const rows = [
        createData('Frozen yoghurt', 'job1', 6.0, 24, 4.0),
        createData('Ice cream sandwich', 'job6', 9.0, 37, 4.3),
        createData('Eclair', 'job2', 16.0, 24, 6.0),
        createData('Cupcake', 'job5', 3.7, 67, 4.3),
        createData('Gingerbread', 'job3', 16.0, 49, 3.9),
    ];

    const styles = theme => ({
        button: {
            margin: theme.spacing.unit,
        },
        leftIcon: {
            marginRight: theme.spacing.unit,
        },
        rightIcon: {
            marginLeft: theme.spacing.unit,
        },
        iconSmall: {
            fontSize: 20,
        },
    });
    return (
        <>

            <Paper style={{
                width:'90%',
                margin:'0px auto'
            }}>
                <Tabs
                    value={0}
                >
                    <Tab label="USERNAME" />
                    <Tab label="PROJECT" />
                </Tabs>
                <Paper>
                    <TextField
                        id="standard-uncontrolled"
                        label="UserName"
                        defaultValue="Fanfan"
                        margin="normal"
                    />
                </Paper>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Project</TableCell>
                            <TableCell>Create_time</TableCell>
                            <TableCell>Active</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.calories}</TableCell>
                                <TableCell>{row.protein}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" >
                                        ACTIVE
                                        <CloudUploadIcon></CloudUploadIcon>
                                    </Button>
                                    <Button variant="contained" color="secondary" >
                                        Delete
                                        <DeleteIcon  />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </>
    );
}
