import { useEffect, useState } from "react";
import axios from "axios";
import {
  CircularProgress,
  Paper,
  Button,
  Stack,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

import ProjectDisplay from "./ProjectDisplay/ProjectDisplay";
import ProjectManager from "./ProjectManager/ProjectManager";

function Project() {
  const [commands, setCommands] = useState(undefined);
  const [project, setProject] = useState(undefined);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMes, setSnackbarMes] = useState("");
  const navigate = useNavigate();

  const currProjectInfo = useSelector((state) => state.currProjectInfo.value);
  useEffect(() => {
    //If the user did not log in, redirect to log in page
    if (
      localStorage.getItem("userId") === null &&
      Cookies.get("userId") == null
    ) {
      navigate("/");
    } else {
      //If the user has logged in, fetch data from server
      async function fetchCommands() {
        let commands;
        try {
          commands = (await axios.get("/api/job/commands")).data;
        } catch (e) {
          setSnackbarOpen(true);
          setSnackbarMes("Error fetching commands: " + e);
          return;
        }
        setCommands(commands.data);
      }

      fetchCommands();
      return () => {
        setCommands(undefined);
      };
    }
  }, [navigate]);

  function handleSnackbarOpen(flag) {
    setSnackbarOpen(flag);
  }

  const snackbarAction = <Button onClick={() => navigate("/")}>Sign In</Button>;

  return (
    <div>
      <Paper variant="outlined" sx={{ p: "10px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ProjectManager
            project={project}
            projectId={currProjectInfo?.projectId}
            setProject={setProject}
          />
        </Stack>
      </Paper>
      {commands !== undefined ? (
        currProjectInfo?.projectId !== undefined && (
          <ProjectDisplay
            commands={commands}
            currProjectInfo={currProjectInfo}
          />
        )
      ) : (
        <CircularProgress color="inherit" size={20} />
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          handleSnackbarOpen(false);
        }}
        message={snackbarMes}
        action={snackbarAction}
      />
    </div>
  );
}

export default Project;
