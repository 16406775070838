import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import * as React from 'react';
import Cookies from 'js-cookie';

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export default function ProjectDelete({
  setProject,
  fetchProjects,
  projectId,
  disabledBtn,
}) {
  const [openDeleteConf, setOpenDeleteConf] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMes, setSnackbarMes] = useState("");

  const handleDeleteConfClickOpen = () => {
    setOpenDeleteConf(true);
  };

  const handleDeleteConfClose = () => {
    setOpenDeleteConf(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbar = (res) => {
    setSnackbarOpen(true);
    if (res.data.code === 0) {
      setSnackbarMes("Delete project success");
    } else {
      setSnackbarMes("Fail to delete project");
    }
  }

  async function handleDeleteProject(projectId) {
    setProject(undefined);
    const res = await axios.post("/api/project/deleteproject", { projectId });
    setSnackbarOpen(true);
    handleSnackbar(res);
    handleDeleteConfClose();
    fetchProjects();
  }
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleDeleteConfClickOpen}
        startIcon={<DeleteOutlineOutlinedIcon />}
        disabled={disabledBtn || (localStorage.getItem("userId") === null  && Cookies.get('userId') == null )}
      >
        Delete
      </Button>
      <Dialog
        open={openDeleteConf}
        onClose={handleDeleteConfClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete the project?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting the project will result removing all job data from the
            database and the corresponding files and folders.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfClose}>Cancel</Button>
          <Button
            onClick={() => handleDeleteProject(projectId)}
            color="error"
            autoFocus
            disabled={localStorage.getItem("userId") === null && Cookies.get('userId') == null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMes}
      />
    </>
  );
}
