import { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Paper,
  Link,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/system";
import { isEmail } from "../utils/strValidator";

export default function SignUp() {
  function Form() {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [repeatPasswordError, setRepeatPasswordError] = useState("");

    const [open, setOpen] = useState(false);
    const [simpleDialogOpen, setSimpleDialogOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [dataPath1, setDataPath1] = useState("");
    const [dataPath2, setDataPath2] = useState("");
    const [dataPath3, setDataPath3] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);

    //Hanle input data format, input data must start with /
    const [errorPath1, setErrorPath1] = useState(false);
    const [errorPath2, setErrorPath2] = useState(false);
    const [errorPath3, setErrorPath3] = useState(false);

    const handleToggle = (flag) => {
      setOpen(flag);
    };

    function handleSimpleDialogOpen(flag) {
      setSimpleDialogOpen(flag);
    }

    const handleDialogCancel = () => {
      setDialogOpen(false);
      setDataPath1("");
      setDataPath2("");
      setDataPath3("");
    };

    const handleDialogConfirm = () => {
      setDialogOpen(false);
    };

    const handleDialogOpen = () => {
      setDialogOpen(true);
    };

    // input validation
    function regForm() {
      let validEmail = false,
        validPassword = false,
        validRepeatPassword = false;
      if (!email) {
        setEmailError("Email is required");
      } else if (!isEmail(email)) {
        setEmailError("Email must be valid");
      } else {
        setEmailError("");
        validEmail = true;
      }
      if (!password) {
        setPasswordError("Password is required");
      } else {
        setPasswordError("");
        validPassword = true;
      }
      if (!repeatPassword || password !== repeatPassword) {
        setRepeatPasswordError("Password does not match");
      } else {
        setRepeatPasswordError("");
        validRepeatPassword = true;
      }
      return validEmail && validPassword && validRepeatPassword;
    }

    async function signIn() {
      //Save null if user did not input a path
      let path1, path2, path3;
      if (dataPath1 !== "") {
        path1 = dataPath1;
      }
      if (dataPath2 !== "") {
        path2 = dataPath2;
      }
      if (dataPath3 !== "") {
        path3 = dataPath3;
      }
      if (regForm()) {
        const form = {
          userName: email,
          userPassword: password,
          dataPath1: path1,
          dataPath2: path2,
          dataPath3: path3,
        };

        handleToggle(true);
        const ps = await axios.post("/api/user/register", form);
        handleToggle(false);
        setSelectedValue(ps.data.msg);
        handleSimpleDialogOpen(true);
        if (ps.data.code === 0) {
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        }
      }
    }

    return (
      <Paper
        sx={{
          width: "300px",
          height: "366px",
          display: "flex",
          padding: "20px",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Sign Up
        </Typography>
        <TextField
          error={!!emailError}
          helperText={emailError}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          error={!!passwordError}
          helperText={passwordError}
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <TextField
          error={!!repeatPasswordError}
          helperText={repeatPasswordError}
          label="Repeat-Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setRepeatPassword(e.target.value)}
          value={repeatPassword}
        />
        <Button variant="text" onClick={handleDialogOpen}>
          Add data paths
        </Button>

        <Dialog
          onClose={handleDialogConfirm}
          open={dialogOpen}
          maxWidth={false}
        >
          <DialogTitle>Input Your Data Paths</DialogTitle>

          <TextField
            error={errorPath1}
            label="Data Path One"
            style={{
              width: "60rem",
              marginLeft: "1.2rem",
              marginRight: "1.2rem",
              marginBottom: "0.7rem",
            }}
            onChange={(e) => {
              if (e.target.value.charAt(0) === "/" || e.target.value === "") {
                setErrorPath1(false);
                setDataPath1(e.target.value);
              } else {
                setErrorPath1(true);
                setDataPath1(e.target.value);
              }
            }}
            value={dataPath1}
          />
          <TextField
            error={errorPath2}
            label="Data Path Two"
            style={{
              width: "60rem",
              marginLeft: "1.2rem",
              marginRight: "1.2rem",
              marginBottom: "0.7rem",
            }}
            onChange={(e) => {
              if (e.target.value.charAt(0) === "/" || e.target.value === "") {
                setErrorPath2(false);
                setDataPath2(e.target.value);
              } else {
                setErrorPath2(true);
                setDataPath2(e.target.value);
              }
            }}
            value={dataPath2}
          />
          <TextField
            error={errorPath3}
            label="Data Path Three"
            helperText={
              "The path must be an absolute path starting with forward slash."
            }
            style={{
              width: "60rem",
              marginLeft: "1.2rem",
              marginRight: "1.2rem",
              marginBottom: "0.7rem",
            }}
            onChange={(e) => {
              if (e.target.value.charAt(0) === "/" || e.target.value === "") {
                setErrorPath3(false);
                setDataPath3(e.target.value);
              } else {
                setErrorPath3(true);
                setDataPath3(e.target.value);
              }
            }}
            value={dataPath3}
          />

          <DialogActions>
            <Button
              onClick={handleDialogCancel}
              sx={{ marginRight: "15px", marginBottom: "10px" }}
            >
              Cancel
            </Button>
            <Button
              disabled={errorPath1 || errorPath2 || errorPath3}
              variant="outlined"
              onClick={handleDialogConfirm}
              sx={{ marginRight: "15px", marginBottom: "10px" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Button variant="contained" onClick={signIn}>
          Create an account
        </Button>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={simpleDialogOpen}
          autoHideDuration={6000}
          onClose={() => {
            handleSimpleDialogOpen(false);
          }}
          message={selectedValue}
        />
      </Paper>
    );
  }

  function CallOut() {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography component="div">
          Already have an account? <Link href="/">Sign In</Link>
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: "100px auto",
        padding: "30px",
        width: "300px",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Form></Form>
      <CallOut></CallOut>
    </Box>
  );
}
