import { createSlice } from "@reduxjs/toolkit";

export const currProjectInfoSlice = createSlice({
  name: "currProjectInfo",
  initialState: {
    value: {
      projectId: undefined,
      projectName: undefined,
      projectPath: undefined,
      projectDescription: undefined,
    },
  },
  reducers: {
    updateCurrProjectInfo: (state, action) => {
      state.value = action.payload;
    },
    modifyCurrProjectInfo: (state, action) => {
      const { item, newValue } = action.payload;
      state.value[item] = newValue;
    },
  },
});

export const { updateCurrProjectInfo, modifyCurrProjectInfo } =
  currProjectInfoSlice.actions;

export default currProjectInfoSlice.reducer;
