import React from "react";
import { TextField } from "@mui/material";

export default function YamlEditor({ ymalRawData, setYmalRawData }) {
  return (
    <>
      <TextField
        multiline
        sx={{ width: "100%" }}
        aria-label="yaml editor"
        minRows={30}
        placeholder="Paste your Yaml File here"
        defaultValue={ymalRawData}
        onChange={(e) => setYmalRawData(e.target.value)}
      />
    </>
  );
}
