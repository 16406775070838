import { Box } from "@mui/system";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";

import {
  Stack,
  Divider,
  Typography,
  Snackbar,
  Chip,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import versions from "./versions.json";

function Main() {
  const [commands, setCommands] = useState(undefined);
  const [jobStatistics, setJobStatistics] = useState(undefined);
  const [recentJobs, setRecentJobs] = useState(undefined);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMes, setSnackbarMes] = useState("");
  const [projectNumbers, setProjectNumbers] = useState("");
  const [totalTime, setTotalTime] = useState(["", ""]);
  const [projectInfor, setProjectInfor] = useState([[], [], []]);
  const [intervalID1, setIntervalID1] = useState(undefined);

  function handleSnackbarOpen(flag) {
    setSnackbarOpen(flag);
  }

  const navigate = useNavigate();

  //Fetch yaml files information from server
  async function fetchCommands() {
    let commandsData;
    try {
      commandsData = (await axios.get("/api/job/commands")).data;

      if (commandsData.data.length !== 0) {
        setCommands(commandsData.data);
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Error fetching commands: " + e);
      return;
    }
  }

  //Fetch yaml files information from server
  async function fetchJobStatistics() {
    let jobsStatistics;
    let recentJobs_;
    let projectInfor1;
    let projectInfor2;
    let projectInfor3;
    let projectIds;
    let totalTimeData;
    let userId = localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : Cookies.get("userId");
    let tempArray = [];
    try {
      //Fetch user's project IDS
      // TODO: Implement Error handling: what if the back end gives bad responses
      projectIds = (
        await axios.post("/api/job/getProjectByUserId", {
          userId: userId,
        })
      ).data;

      //Save project IDs in an array
      for (let i = 0; i < projectIds.data.length; i++) {
        tempArray.push(projectIds.data[i]);
      }

      if (tempArray.length !== 0) {
        //Fetch project name and description
        projectInfor1 = (
          await axios.post("/api/job/getGroupProjectByProjectId", {
            projectIds: tempArray,
          })
        ).data;

        //Count the RUNNING jobs of each project
        projectInfor2 = (
          await axios.post("/api/job/getJobCountGroupInfo", {
            jobStatus: "RUNNING",
            projectIds: tempArray,
          })
        ).data;

        //Count the total number of jobs of each project
        projectInfor3 = (
          await axios.post("/api/job/getTotalJobCountPerProject", {
            projectIds: tempArray,
            deleted: false,
          })
        ).data;

        setProjectInfor([
          projectInfor1.data,
          projectInfor2.data,
          projectInfor3.data,
        ]);

        setProjectNumbers(projectIds.data.length);

        //Count the total number of jobs
        jobsStatistics = (
          await axios.post("/api/job/getTotalJob", {
            projectIds: tempArray,
            deleted: false,
          })
        ).data;

        setJobStatistics(jobsStatistics.data.length);

        //Count each status of the first 10 jobs
        recentJobs_ = (
          await axios.post("/api/job/getSortedJobs", {
            limit: 10,
            projectIds: tempArray,
          })
        ).data;

        let running = 0;
        let queued = 0;
        let failed = 0;
        let finished = 0;
        for (let i = 0; i < recentJobs_.data.length; i++) {
          if (recentJobs_.data[i]["jobStatus"] === "RUNNING") {
            running++;
          } else if (recentJobs_.data[i]["jobStatus"] === "QUEUED") {
            queued++;
          } else if (recentJobs_.data[i]["jobStatus"] === "FINISHED") {
            finished++;
          } else if (recentJobs_.data[i]["jobStatus"] === "FAILED") {
            failed++;
          }
        }

        setRecentJobs([running, queued, finished, failed]);

        //Fetch the total start time and finish time
        totalTimeData = (
          await axios.post("/api/job/getJobTimeInfo", {
            projectIds: tempArray,
          })
        ).data;

        let totalTime = 0;
        for (let i = 0; i < totalTimeData.data.length; i++) {
          //Calculate the total time
          if (totalTimeData.data[i] !== null) {
            totalTime =
              totalTime +
              (totalTimeData.data[i]["finishTime"] -
                totalTimeData.data[i]["startTime"]);
          }
        }

        let timeHour = totalTime / 3600000;
        let hour = Math.round(timeHour % 24);
        let day = Math.floor(timeHour / 24);
        setTotalTime([day, hour]);
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Error fetching jobs information: " + e);
      setIntervalID1(clearInterval(intervalID1));
      return;
    }
  }

  useEffect(() => {
    //If the user did not log in, redirect to log in page
    if (
      localStorage.getItem("userId") === null &&
      Cookies.get("userId") == null
    ) {
      navigate("/");
    } else {
      fetchCommands();
      fetchJobStatistics();
      setIntervalID1(setInterval(() => fetchJobStatistics(), 30000));

      return () => {
        setCommands(undefined);
        setIntervalID1(clearInterval(intervalID1));
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  //Display project cards
  function DisplayProjectCard({ projectInfor_ }) {
    let tempArray = [];
    for (let i = 0; i < projectInfor_[0].length; i++) {
      tempArray.push(
        <Grid
          item
          key={i}
          xs={12}
          sm={6}
          md={4}
          sx={{
            mt: 1,
          }}
        >
          <Card
            sx={{
              height: "220px",
              border: "1.5px solid #eeeeee",
              cursor: "pointer",
            }}
            elevation={0}
            onClick={() =>
              goToSpecificProjectPage(
                projectInfor_[0][i]
                  ? projectInfor_[0][i]["projectId"]
                  : undefined
              )
            }
          >
            <CardHeader
              title={
                projectInfor_[0][i] ? projectInfor_[0][i]["projectName"] : ""
              }
              titleTypographyProps={{ align: "left", variant: "subtitle1" }}
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                height: "45px",
              }}
            />
            <CardContent sx={{ pr: 0 }}>
              <Box
                sx={{
                  justifyContent: "left",
                  alignItems: "baseline",
                }}
              >
                <Typography variant="subtitle2">
                  Running jobs:{" "}
                  {projectInfor_[1][i]
                    ? projectInfor_[1][i]["_count"]["jobId"]
                    : "0"}
                </Typography>
                <Typography variant="subtitle2">
                  Total jobs:{" "}
                  {projectInfor_[2][i]
                    ? projectInfor_[2][i]["_count"]["jobId"]
                    : "0"}
                </Typography>
                <div
                  style={{
                    width: "100%",
                    overflow: "auto",
                    height: "110px",
                    overflowX: "hidden",
                  }}
                >
                  <Typography variant="subtitle2" sx={{ paddingTop: "8px" }}>
                    Note:{" "}
                    {projectInfor_[0][i]
                      ? projectInfor_[0][i]["projectDescription"]
                      : ""}
                  </Typography>
                </div>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      );
    }
    //If there is no project, display a message
    if (projectInfor_[0].length === 0) {
      return (
        <Typography variant="h6" sx={{ padding: "30px 0 0 50px" }}>
          No project was found
        </Typography>
      );
    } else {
      return tempArray;
    }
  }

  //Redirect to specific prject page, when click PROJECT name
  function goToSpecificProjectPage(id) {
    if (id) {
      navigate("/project/?projectId=" + id);
    }
  }

  //Redirect to prject page, when click CREATE PROJECT
  function goToProjectPage() {
    navigate("/project");
  }

  //Handle tool box description
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //Tool box display
  function displayToolList(commands) {
    if (commands) {
      return commands.map((item, i) => (
        <Accordion
          expanded={expanded === "t" + i}
          onChange={handleChange("t" + i)}
          key={i}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontSize={14}>{item.custom_name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={13}>{item.description}</Typography>
          </AccordionDetails>
        </Accordion>
      ));
    }
  }

  //Display version inforamtion
  const stylechip = {
    "& .MuiChip-label": {
      width: "45px",
      textAlign: "center",
    },
  };
  const whatIsNew = versions.map((item, i) => (
    <div style={{ marginLeft: "10px" }} key={i + "v"}>
      <Divider sx={{ padding: "0 30px 0 30px" }}>
        {" "}
        <Typography fontSize={14}>{item.version} </Typography>
      </Divider>
      {item.feat.map((f, i) => (
        <div
          style={{ width: "100%", display: "flex", margin: "6px 10px 6px 0px" }}
          key={i + "fe"}
        >
          <Chip
            label="Feat"
            variant="outlined"
            size="small"
            color="primary"
            sx={stylechip}
          />{" "}
          <Typography fontSize={14} sx={{ marginLeft: "10px" }}>
            {f}
          </Typography>
        </div>
      ))}
      {item.fix.map((f, i) => (
        <div
          style={{ width: "100%", display: "flex", margin: "6px 10px 6px 0px" }}
          key={i + "fi"}
        >
          <Chip
            label="Fix"
            variant="outlined"
            size="small"
            color="error"
            sx={stylechip}
          />{" "}
          <Typography fontSize={14} sx={{ marginLeft: "10px" }}>
            {f}
          </Typography>
        </div>
      ))}
    </div>
  ));

  //Handle data paths edit
  const [openPathEditor, setOpenPathEditor] = useState(false);

  //Three data paths
  const [dataPathOne, setDataPathOne] = useState("");
  const [dataPathTwo, setDataPathTwo] = useState("");
  const [dataPathThree, setDataPathThree] = useState("");

  //Hanle input data format, input data must start with /
  const [errorPath1, setErrorPath1] = useState(false);
  const [errorPath2, setErrorPath2] = useState(false);
  const [errorPath3, setErrorPath3] = useState(false);

  //Open path editor modal, fetch datapaths from database
  const handlePathEditorOpen = () => {
    setOpenPathEditor(true);
    fetchDataPath();
  };

  //Close path editor modal or user clicks cancel
  const handlePathEditorClose = () => {
    setOpenPathEditor(false);
  };

  //When user click confirm, updata data paths
  const handlePathEditorConfirm = () => {
    setOpenPathEditor(false);
    updateDataPath(dataPathOne, dataPathTwo, dataPathThree);
  };

  //Fetch data paths from database
  async function fetchDataPath() {
    try {
      let userId_ = localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : Cookies.get("userId");
      if (userId_) {
        let dataPaths = (
          await axios.post("/api/user/info", {
            userId: userId_,
          })
        ).data;
        if (dataPaths.code === 0) {
          setDataPathOne(
            dataPaths.data["dataPath1"] ? dataPaths.data["dataPath1"] : ""
          );
          setDataPathTwo(
            dataPaths.data["dataPath2"] ? dataPaths.data["dataPath2"] : ""
          );
          setDataPathThree(
            dataPaths.data["dataPath3"] ? dataPaths.data["dataPath3"] : ""
          );
        } else {
          setSnackbarOpen(true);
          setSnackbarMes("Failed to fetch data paths: bad response");
        }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Failed to fetch data paths: " + e);
    }
  }

  //This function update data paths
  async function updateDataPath(dataPath1_, dataPath2_, dataPath3_) {
    try {
      let userId_ = localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : Cookies.get("userId");

      // TODO: simpler way to cover all the cases? Also: Error Handlers need to be implemented
      if (userId_) {
        if (dataPath1_ !== "" && dataPath2_ !== "" && dataPath3_ !== "") {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: dataPath1_,
            dataPath2: dataPath2_,
            dataPath3: dataPath3_,
          });
        } else if (
          dataPath1_ === "" &&
          dataPath2_ === "" &&
          dataPath3_ === ""
        ) {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: null,
            dataPath2: null,
            dataPath3: null,
          });
        } else if (
          dataPath1_ === "" &&
          dataPath2_ !== "" &&
          dataPath3_ !== ""
        ) {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: null,
            dataPath2: dataPath2_,
            dataPath3: dataPath3_,
          });
        } else if (
          dataPath1_ !== "" &&
          dataPath2_ === "" &&
          dataPath3_ !== ""
        ) {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: dataPath1_,
            dataPath2: null,
            dataPath3: dataPath3_,
          });
        } else if (
          dataPath1_ !== "" &&
          dataPath2_ !== "" &&
          dataPath3_ === ""
        ) {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: dataPath1_,
            dataPath2: dataPath2_,
            dataPath3: null,
          });
        } else if (
          dataPath1_ === "" &&
          dataPath2_ === "" &&
          dataPath3_ !== ""
        ) {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: null,
            dataPath2: null,
            dataPath3: dataPath3_,
          });
        } else if (
          dataPath1_ !== "" &&
          dataPath2_ === "" &&
          dataPath3_ === ""
        ) {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: dataPath1_,
            dataPath2: null,
            dataPath3: null,
          });
        } else {
          await axios.post("/api/user/updateJobPath", {
            userId: userId_,
            dataPath1: null,
            dataPath2: dataPath2_,
            dataPath3: null,
          });
        }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Failed to update data path: " + e);
    }
  }

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      <Container
        maxWidth="false"
        component="main"
        sx={{
          mt: 1.5,
        }}
      >
        <Grid
          container
          spacing={1.5}
          alignItems="stretch"
          sx={{ marginBottom: "15px" }}
        >
          <Grid item xs={12} md={12} lg={6.5}>
            <Grid
              container
              spacing={1.5}
              direction="row"
              alignItems="flex-start"
            >
              <Grid item xs={12} md={6} lg={5}>
                <Paper sx={{ height: "120px" }} variant="outlined">
                  <div
                    style={{ textAlign: "left", padding: "5px 5px  0  10px " }}
                  >
                    <Typography variant="h6">Recent Jobs</Typography>
                  </div>
                  <div>
                    <table
                      style={{
                        width: "100%",
                        height: "75px",
                        margin: "0 5px 5px 5px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <Stack direction="row">
                              {" "}
                              <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ color: "#006ddb", paddingTop: "2px" }}
                              />
                              <Typography fontSize={15}>
                                RUNNING: {recentJobs ? recentJobs[0] : 0}
                              </Typography>{" "}
                            </Stack>
                          </td>
                          <td>
                            {" "}
                            <Stack direction="row">
                              {" "}
                              <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ color: "#b66dff", paddingTop: "2px" }}
                              />
                              <Typography fontSize={15}>
                                QUEUED: {recentJobs ? recentJobs[1] : 0}
                              </Typography>
                            </Stack>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <Stack direction="row">
                              <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ color: "#22cf22", paddingTop: "2px" }}
                              />
                              <Typography fontSize={15}>
                                FINISHED: {recentJobs ? recentJobs[2] : 0}
                              </Typography>
                            </Stack>
                          </td>
                          <td>
                            <Stack direction="row">
                              <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ color: "#920000", paddingTop: "2px" }}
                              />
                              <Typography fontSize={15}>
                                FAILED: {recentJobs ? recentJobs[3] : 0}
                              </Typography>
                            </Stack>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={7}>
                <Paper sx={{ height: "120px" }} variant="outlined">
                  <table
                    style={{
                      width: "100%",
                      margin: "0 5px 5px 0",
                      height: "110px",
                      textAlign: "center",
                      paddingTop: "5px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <Typography variant="h6">Projects</Typography>
                        </td>
                        <td>
                          <Typography variant="h6">Jobs</Typography>
                        </td>
                        <td>
                          <Typography variant="h6">Total Time</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="h4"
                            style={{ paddingTop: "10px" }}
                          >
                            {projectNumbers ? projectNumbers : 0}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            variant="h4"
                            style={{ paddingTop: "10px" }}
                          >
                            {jobStatistics ? jobStatistics : 0}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            variant="h4"
                            style={{ paddingTop: "10px" }}
                          >
                            {totalTime[0] ? totalTime[0] : 0}d{" "}
                            {totalTime[1] ? totalTime[1] : 0}hr
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Grid
                  container
                  spacing={1.5}
                  direction="column"
                  alignItems="stretch"
                >
                  <Grid item xs={12} md={12} lg={5}>
                    <Paper sx={{ height: "750px" }} variant="outlined">
                      <div style={{ textAlign: "left", padding: "10px" }}>
                        <Typography variant="h6">What's New</Typography>
                      </div>
                      <Divider />
                      <div
                        style={{
                          height: "80%",
                          overflow: "auto",
                          overflowX: "hidden",
                          marginTop: "5px",
                        }}
                      >
                        {whatIsNew}
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={7}>
                <Paper sx={{ height: "750px" }} variant="outlined">
                  <div style={{ textAlign: "left", padding: "10px" }}>
                    <Typography variant="h6">Tool Box</Typography>
                  </div>
                  <Divider />
                  <div
                    style={{
                      height: "92.8%",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {displayToolList(commands)}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={5.5}>
            <Paper
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "881px",
              }}
            >
              <Stack direction="row" spacing={1}>
                <div
                  style={{ width: "45%", textAlign: "left", padding: "10px" }}
                >
                  <Typography variant="h6">Project List</Typography>
                </div>
                <div
                  style={{
                    width: "50%",
                    textAlign: "right",
                    padding: "15px 10px 0 10px",
                  }}
                >
                  <Stack direction="row" spacing={3} justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handlePathEditorOpen}
                    >
                      Edit Data Path
                    </Button>

                    <Dialog
                      open={openPathEditor}
                      onClose={handlePathEditorClose}
                    >
                      <DialogTitle>{"Edit Your Data Paths"}</DialogTitle>
                      <DialogContent>
                        <Stack
                          direction="column"
                          spacing={2}
                          sx={{ width: "500px", marginTop: "20px" }}
                        >
                          <TextField
                            size="small"
                            error={errorPath1}
                            fullWidth
                            label="Data Path One"
                            InputLabelProps={{ shrink: true }}
                            value={dataPathOne}
                            onChange={(e) => {
                              if (
                                e.target.value.charAt(0) === "/" ||
                                e.target.value === ""
                              ) {
                                setErrorPath1(false);
                                setDataPathOne(e.target.value);
                              } else {
                                setErrorPath1(true);
                                setDataPathOne(e.target.value);
                              }
                            }}
                          ></TextField>
                          <TextField
                            size="small"
                            error={errorPath2}
                            fullWidth
                            label="Data Path Two"
                            InputLabelProps={{ shrink: true }}
                            value={dataPathTwo}
                            onChange={(e) => {
                              if (
                                e.target.value.charAt(0) === "/" ||
                                e.target.value === ""
                              ) {
                                setErrorPath2(false);
                                setDataPathTwo(e.target.value);
                              } else {
                                setErrorPath2(true);
                                setDataPathTwo(e.target.value);
                              }
                            }}
                          ></TextField>
                          <TextField
                            size="small"
                            error={errorPath3}
                            helperText={
                              "The path must be an absolute path starting with forward slash."
                            }
                            fullWidth
                            label="Data Path Three"
                            InputLabelProps={{ shrink: true }}
                            value={dataPathThree}
                            onChange={(e) => {
                              if (
                                e.target.value.charAt(0) === "/" ||
                                e.target.value === ""
                              ) {
                                setErrorPath3(false);
                                setDataPathThree(e.target.value);
                              } else {
                                setErrorPath3(true);
                                setDataPathThree(e.target.value);
                              }
                            }}
                          ></TextField>
                        </Stack>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handlePathEditorClose}
                          size="small"
                          sx={{ marginRight: "10px", marginBottom: "10px" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={errorPath1 || errorPath2 || errorPath3}
                          onClick={handlePathEditorConfirm}
                          variant="outlined"
                          size="small"
                          sx={{ marginRight: "15px", marginBottom: "10px" }}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Button
                      variant="outlined"
                      size="small"
                      onClick={goToProjectPage}
                    >
                      Go To Project
                    </Button>
                  </Stack>
                </div>
              </Stack>
              <Divider sx={{ marginBottom: "5px" }} />
              <div
                style={{
                  height: "95%",
                  overflow: "auto",
                  overflowX: "hidden",
                  padding: "0rem 20px 10px 20px",
                }}
              >
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  sx={{ overflow: "auto" }}
                >
                  <DisplayProjectCard projectInfor_={projectInfor} />
                </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          handleSnackbarOpen(false);
        }}
        message={snackbarMes}
      />
    </React.Fragment>
  );
}

export default Main;
