import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Tab,
  Tabs,
  Paper,
  Chip,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Snackbar,
  IconButton,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Divider,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import CloseIcon from "@mui/icons-material/Close";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import defaultImg from "../images/default/jobCardDefault.png";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

import Overview from "../Pages/Overview";
import Inputs from "../Pages/Inputs";
import Visualization from "../Pages/Visualization";
import Outputs from "../FileDisplay/JobFileDisplay";
import "./treeView.css";

import { useSelector } from "react-redux";

export default function ItemInWindow({
  commands,
  needUpdate,
  forceUpdate,
  setCloneCmdArgs,
}) {
  // job model params
  const [openJob, setOpen] = useState(false);
  const [modelJobInfo, setModelJobInfo] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMes, setSnackbarMes] = useState("");

  const handleClickOpen = (param) => {
    setModelJobInfo(param);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleSnackbarOpen(flag) {
    setSnackbarOpen(flag);
  }

  return (
    <div>
      <JobDisplay
        needUpdate={needUpdate}
        handleClickOpen={handleClickOpen}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMes={setSnackbarMes}
        commands={commands}
      />
      <JobModel
        commands={commands}
        openJob={openJob}
        handleClose={handleClose}
        modelJobInfo={modelJobInfo}
        setCloneCmdArgs={setCloneCmdArgs}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMes={setSnackbarMes}
        forceUpdate={forceUpdate}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          handleSnackbarOpen(false);
        }}
        message={snackbarMes}
      />
    </div>
  );
}

function JobModel({
  commands,
  openJob,
  handleClose,
  modelJobInfo,
  setCloneCmdArgs,
  setSnackbarOpen,
  setSnackbarMes,
  forceUpdate,
  currProjectInfo,
}) {
  const [value, setValue] = useState(0);
  const [openDeleteJobConf, setOpenDeleteJobConf] = useState(false);

  // delet job confirmation modal
  const handleDeleteJobConfClickOpen = () => {
    setOpenDeleteJobConf(true);
  };

  const handleDeleteJobConfClose = () => {
    setOpenDeleteJobConf(false);
  };

  async function handleDeleteJob(jobId) {
    // kill job
    killJob(modelJobInfo["jobId"]);

    // delete job
    let res = await axios.post("/api/job/deletejob", { jobId });
    setSnackbarOpen(true);
    if (res.data.code === 0) {
      setSnackbarMes("Delete job success");
    } else {
      setSnackbarMes("Fail to delete job");
    }
    forceUpdate();
    handleDeleteJobConfClose();
    handleClose();
  }

  // Tab control
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  async function killJob(jobId) {
    let re = await axios.post("/api/job/kill", { jobId: jobId });
    if (re.data.code === -1) {
      setSnackbarOpen(true);
      setSnackbarMes(re.data.msg);
    }
  }

  // control the killBtn via self-checking variable(each render triggers this check)
  // If the jobStatus is "FINISHED" or "FAILED", this variable is true
  // otherwise, it is false
  const disabledKillBtn =
    modelJobInfo.jobStatus === "FINISHED" ||
    modelJobInfo.jobStatus === "FAILED";

  const [killBtnDisabled, setKillBtnDisabled] = useState(disabledKillBtn);

  // when the database is changed, the monitor automatically set the disabled status of kll button and delete button
  useEffect(() => {
    setKillBtnDisabled(disabledKillBtn);
  }, [modelJobInfo, disabledKillBtn]);

  //This function update job tags
  async function updateJobTag(jobTag_) {
    try {
      if (modelJobInfo["jobId"] !== undefined) {
        await axios.post("/api/job/tag", {
          jobTag: JSON.stringify(jobTag_),
          jobId: modelJobInfo["jobId"],
        });
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Failed to update Jobs: " + e);
    }
  }

  //Handle tag display
  const [value_, setValue_] = useState([]);
  const [holder, setHolder] = useState("Add a job tag");

  //when add a tag, update job tag, user can add up to 5 tags
  const handleChange_ = (newValue) => {
    if (newValue.length < 5) {
      updateJobTag(newValue);
      setValue_(newValue);
      setHolder("Add a job tag");
    } else if (newValue.length === 5) {
      updateJobTag(newValue);
      setValue_(newValue);
      setHolder("Up to 5 tags");
    } else {
      setHolder("Up to 5 tags");
    }
  };

  useEffect(() => {
    //Fetch job tags from database
    async function fetchJobTags(jobId_) {
      try {
        if (jobId_ !== undefined) {
          let jobsTag = (
            await axios.post("/api/job/jobTag", {
              jobId: jobId_,
            })
          ).data;

          if (jobsTag.code === 0) {
            //Set job tags when open job modal
            let tags = JSON.parse(jobsTag.data["jobTag"]);
            setValue_(tags);
            if (tags.length === 5) {
              setHolder("Up to 5 tags");
            } else {
              setHolder("Add a job tag");
            }
          } else {
            setSnackbarOpen(true);
            setSnackbarMes("Failed to fetch job tags: bad response");
          }
        }
      } catch (e) {
        setSnackbarOpen(true);
        setSnackbarMes("Failed to fetch job tags: " + e);
      }
    }
    //Fetch job tags when open job modal
    if (modelJobInfo["jobId"]) {
      fetchJobTags(modelJobInfo["jobId"]);
    } else {
      setValue_([]);
    }
  }, [modelJobInfo, setSnackbarMes, setSnackbarOpen]);

  //handle scroll top or bottom
  const bottomRef = useRef(null);
  const topRef = useRef(null);

  const toBottom = () => {
    bottomRef.current?.scrollIntoView();
  };

  const toTop = () => {
    topRef.current?.scrollIntoView();
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={"lg"} open={openJob} onClose={handleClose}>
        <Stack direction="row" spacing={1}>
          <div style={{ width: "10%" }}>
            <DialogTitle sx={{ textTransform: "uppercase" }}>
              {modelJobInfo["jobName"]}{" "}
            </DialogTitle>{" "}
          </div>
          <Grid>
            <Chip
              style={{
                maxWidth: "100px",
                marginRight: "1px",
                marginTop: "15px",
              }}
              size="medium"
              label={
                <Typography fontSize={11} sx={{ padding: "8px 0 8px 0" }}>
                  {modelJobInfo["commandName"]}
                </Typography>
              }
            />
          </Grid>
          <Box sx={{ width: "86%", pt: "10px" }}>
            <MuiChipsInput
              helperText={value_.length > 0 ? "Double click to edit a tag" : ""}
              clearInputOnBlur
              disableDeleteOnBackspace
              value={value_}
              hideClearAll
              variant="standard"
              size="small"
              placeholder={holder}
              onChange={handleChange_}
              validate={(chipValue) => {
                return {
                  isError: chipValue.length > 15 || value_.includes(chipValue),
                  textError:
                    chipValue.length > 15
                      ? "The tag must be less than 15 characters"
                      : "The tag has been added",
                };
              }}
            />
          </Box>
          <Box sx={{ width: "4%", textAlign: "right" }}>
            <IconButton
              onClick={handleClose}
              sx={{ cursor: "pointer", marginTop: "2px", marginRight: "5px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>

        <Box sx={{ m: "0 0 0 10px", display: "flex" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ width: "95%" }}
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Inputs" {...a11yProps(1)} />
            <Tab label="Visualization" {...a11yProps(2)} />
            <Tab label="Outputs" {...a11yProps(3)} />
          </Tabs>
          <div style={{ width: "5%", textAlign: "right" }}>
            <Tooltip
              title={
                <p style={{ fontSize: 12, marginTop: 3, marginBottom: 3 }}>
                  Scroll to bottom
                </p>
              }
              placement="left"
            >
              <IconButton
                component="label"
                onClick={toBottom}
                sx={{ marginRight: "10px" }}
              >
                <ArrowCircleDownIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
        <Box
          sx={{
            height: "80vh",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <div ref={topRef} />

          <TabPanel value={value} index={0}>
            <Overview
              data={modelJobInfo}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMes={setSnackbarMes}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Inputs commands={commands} data={modelJobInfo} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Visualization
              data={modelJobInfo}
              commands={commands}
              currProjectInfo={currProjectInfo}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Outputs data={modelJobInfo} />
          </TabPanel>
          <div ref={bottomRef} />
        </Box>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Tooltip
            title={
              <p style={{ fontSize: 12, marginTop: 3, marginBottom: 3 }}>
                Scroll to top
              </p>
            }
            placement="left"
          >
            <IconButton
              component="label"
              onClick={toTop}
              sx={{ marginRight: "8px" }}
            >
              <ArrowCircleUpIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "0",
          }}
        >
          <Button color="error" onClick={handleDeleteJobConfClickOpen}>
            Delete Job
          </Button>
          <div>
            <Button
              disabled={killBtnDisabled}
              onClick={() => {
                setKillBtnDisabled(true);
                killJob(modelJobInfo["jobId"]);
              }}
            >
              Kill Job
            </Button>
            <Button
              onClick={() => {
                handleClose();
                setCloneCmdArgs(modelJobInfo);
              }}
            >
              Clone Job
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteJobConf}
        onClose={handleDeleteJobConfClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete the job?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting the job will result removing all job data from the database
            and the corresponding files and folders.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteJobConfClose}>Cancel</Button>
          <Button
            onClick={() => handleDeleteJob(modelJobInfo.jobId)}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function JobDisplay({
  needUpdate,
  handleClickOpen,
  setSnackbarOpen,
  setSnackbarMes,
  commands,
}) {
  //jobs do not need updated perioded
  const [allJobsInfo, setAllJobsInfo] = useState([]);

  //job Images
  const [imagesInfo, setImagesInfo] = useState([]);

  //jobs need to be updated perioded
  const [allJobsInfoQR, setAllJobsInfoQR] = useState([]);

  //job Images
  const [imagesInfoQR, setImagesInfoQR] = useState([]);

  // redux
  const currProjectInfo = useSelector((state) => state.currProjectInfo.value);
  //Initialize the SQL queries
  const sqlQueryFE = currProjectInfo
    ? `SELECT * FROM Job WHERE deleted IS FALSE AND jobStatus IN ('FINISHED','FAILED') AND projectId = '${currProjectInfo.projectId}'`
    : "'";
  const sqlQueryQR = currProjectInfo
    ? `SELECT * FROM Job WHERE deleted IS FALSE AND jobStatus IN ('QUEUED','RUNNING') AND projectId = '${currProjectInfo.projectId}'`
    : "";
  const [jobsIDQuery, setJobsIDQuery] = useState(undefined);
  const [intervalID1, setIntervalID1] = useState(undefined);

  //initialize statuses, tags, and types
  const [checkStatus, setCheckStatus] = useState([false, false, false, false]);
  const [jobStatus] = useState([null, null, null, null]);

  const [checkTag, setCheckTag] = useState([]);
  const [jobTags] = useState([]);

  const [checkType, setCheckType] = useState([]);
  const [jobTypes] = useState([]);

  //The numbers of job types and the unique command names list
  let nameLength = 0;
  let commandNames = [];

  //The number of job tags and unique tag names
  let tagLength = 0;
  let tagName = [];

  //Status list
  const statuses = ["'QUEUED'", "'RUNNING'", "'FINISHED'", "'FAILED'"];

  //Used to display which filter the user selected
  const [statusList, setStatusList] = useState("");
  const [typeList, setTypeList] = useState("");
  const [tagList, setTagList] = useState("");

  //Job names array fetched from database
  const [jobTagsName, setJobTagsName] = useState([]);

  //job card image style
  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 110,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: 80,
    },
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: 0.15,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
    },
  }));

  //job card image style
  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  });

  //image background--grey
  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  }));

  // Handle filter open  and close
  const [filterOpen, setFilterOpen] = useState(false);
  function handleFilterOpen() {
    //when open job filter modal, fetch job tags
    fetchJobTags(currProjectInfo);
    setFilterOpen(true);
  }

  //when filter modal close, set filters to default values
  function handleFilterClose() {
    setFilterOpen(false);

    setCheckStatus([false, false, false, false]);
    jobStatus.fill(null);

    let tempArray = checkType.fill(false);
    setCheckType([...tempArray]);
    jobTypes.fill(null);

    let tempTagArray = checkTag.fill(false);
    setCheckTag([...tempTagArray]);
    jobTags.fill(null);
  }

  // when user clicked confirm button, close modal, keep the filters user selected
  function handleFilterCloseConfirm() {
    setFilterOpen(false);
  }

  useEffect(() => {
    // when page load, get the jobs from dtabase
    setIntervalID1(clearInterval(intervalID1));
    if (currProjectInfo !== undefined) {
      setTypeList("");
      setStatusList("");
      setTagList("");

      //Fetch jobs Finished and Eixt with Error
      fetchJobsFE(sqlQueryFE);

      //Fetch jobs Queued and running, and get the SQL query which query databasse by job ids
      fetchJobsQRIDs(sqlQueryQR);

      //Fetch jobs by ids each 10s
      if (jobsIDQuery) {
        setIntervalID1(setInterval(() => fetchJobsQR(jobsIDQuery), 2000));
      }
    }
    return () => setIntervalID1(clearInterval(intervalID1));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currProjectInfo, jobsIDQuery, needUpdate]);

  //Fetch job tags from database
  async function fetchJobTags(currProjectInfo) {
    try {
      if (currProjectInfo !== undefined) {
        let jobsTags = (
          await axios.post("/api/job/jobTags", {
            projectId: currProjectInfo.projectId,
          })
        ).data;
        if (jobsTags.code === 0) {
          setJobTagsName(jobsTags.data);
        } else {
          setSnackbarOpen(true);
          setSnackbarMes("Failed to fetch job tags: bad response");
        }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Failed to fetch job tags: " + e);
    }
  }

  // TODO: change logic for sql injection
  //Fetch jobs Finished and Exit with error
  async function fetchJobsFE(sqlQueryFE_) {
    try {
      if (currProjectInfo !== undefined) {
        let jobsInfoFE = (
          await axios.post("/api/job/jobs", {
            sql: sqlQueryFE_,
          })
        ).data;

        if (jobsInfoFE.code === 0) {
          setAllJobsInfo(jobsInfoFE.data);

          //Fetch images
          let imageArray = [];
          for (let i = 0; i < jobsInfoFE.data.length; i++) {
            let jobIdInfo = jobsInfoFE.data[i].jobId;
            var imgUrls = [];
            const info = (
              await axios.post("/api/job/jobFiles/", { jobId: jobIdInfo })
            ).data.data;
            // filter by "postprocess"

            if (info) {
              for (let p = 0; p < info.length; p++) {
                if (
                  info[p].jobFilePath.split("/")[8] === "postprocess" &&
                  !info[p].jobFilePath.includes(".html")
                ) {
                  imgUrls.push({
                    img: info[p].jobFilePath,
                    title: info[p].fileName,
                  });
                  break;
                }
              }
            }

            if (imgUrls.length > 0) {
              imageArray.push(imgUrls[0].img);
            } else {
              imageArray.push(defaultImg);
            }
          }
          setImagesInfo(imageArray);
        } else {
          setSnackbarOpen(true);
          setSnackbarMes("Failed to retrive Jobs: " + jobsInfoFE.msg);
          setIntervalID1(clearInterval(intervalID1));
        }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Failed to retrive Jobs: " + e);
      setIntervalID1(clearInterval(intervalID1));
    }
  }

  //Fetch jobs Queued and Running
  async function fetchJobsQR(sqlQueryQR_) {
    try {
      if (currProjectInfo !== undefined) {
        let jobsInfoQR = (
          await axios.post("/api/job/jobs", {
            sql: sqlQueryQR_,
          })
        ).data;

        if (jobsInfoQR.code === 0) {
          setAllJobsInfoQR(jobsInfoQR.data);

          //Fetch images
          let imageArray = [];
          for (let i = 0; i < jobsInfoQR.data.length; i++) {
            let jobIdInfo = jobsInfoQR.data[i].jobId;
            var imgUrls = [];
            let info = (
              await axios.post("/api/job/jobFiles/", { jobId: jobIdInfo })
            ).data.data;
            // filter by "postprocess"

            if (info) {
              for (let p = 0; p < info.length; p++) {
                if (
                  info[p].jobFilePath.split("/")[8] === "postprocess" &&
                  !info[p].jobFilePath.includes(".html") &&
                  !info[p].jobFilePath.includes(".pdb") &&
                  !info[p].jobFilePath.includes(".mrc")
                ) {
                  imgUrls.push({
                    img: info[p].jobFilePath,
                    title: info[p].fileName,
                  });
                  break;
                }
              }
            }

            if (imgUrls.length > 0) {
              imageArray.push(imgUrls[0].img);
            } else {
              imageArray.push(defaultImg);
            }
          }
          setImagesInfoQR(imageArray);
        } else {
          setSnackbarOpen(true);
          setSnackbarMes("Failed to retrive Jobs: " + jobsInfoQR.msg);
          setIntervalID1(clearInterval(intervalID1));
        }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Failed to retrive Jobs: " + e);
      setIntervalID1(clearInterval(intervalID1));
    }
  }

  //Fetch jobs Queued and Running then set the SQL query, which was used to query jobs by job ids
  async function fetchJobsQRIDs(sqlQueryQR_) {
    try {
      if (currProjectInfo !== undefined) {
        let jobsInfoQR = (
          await axios.post("/api/job/jobs", {
            sql: sqlQueryQR_,
          })
        ).data;

        if (jobsInfoQR.code === 0) {
          setAllJobsInfoQR(jobsInfoQR.data);
          let tempArray = [];
          let imageArray = [];
          if (jobsInfoQR.data.length !== 0) {
            for (let i = 0; i < jobsInfoQR.data.length; i++) {
              tempArray.push(jobsInfoQR.data[i].jobId);

              let jobIdInfo = jobsInfoQR.data[i].jobId;
              var imgUrls = [];
              //Fetch images
              let info = (
                await axios.post("/api/job/jobFiles/", { jobId: jobIdInfo })
              ).data.data;
              // filter by "postprocess"
              if (info) {
                for (let p = 0; p < info.length; p++) {
                  if (
                    info[p].jobFilePath.split("/")[8] === "postprocess" &&
                    !info[p].jobFilePath.includes(".html")
                  ) {
                    imgUrls.push({
                      img: info[p].jobFilePath,
                      title: info[p].fileName,
                    });
                    break;
                  }
                }
              }
              if (imgUrls.length > 0) {
                imageArray.push(imgUrls[0].img);
              } else {
                imageArray.push(defaultImg);
              }
            }
            setImagesInfoQR(imageArray);

            //This SQL query will get jobs by job ids
            setJobsIDQuery(
              `SELECT * FROM Job WHERE deleted IS FALSE AND projectId = '${
                currProjectInfo.projectId
              }' AND jobId IN (${tempArray.map((element) => `'${element}'`)})`
            );
          } else {
            setJobsIDQuery(undefined);
          }
        } else {
          setSnackbarOpen(true);
          setSnackbarMes("Failed to retrive Jobs: a" + jobsInfoQR.msg);
          setIntervalID1(clearInterval(intervalID1));
        }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMes("Failed to retrive Jobs: " + e);
      setIntervalID1(clearInterval(intervalID1));
    }
  }

  //This function display unique tags on filter modal
  function GetTaglist({ jobtagsName_ }) {
    let rows = [];
    let tempList = [];
    for (let i = 0; i < jobtagsName_.length; i++) {
      for (let j = 0; j < JSON.parse(jobtagsName_[i]).length; j++) {
        tempList.push(JSON.parse(jobtagsName_[i])[j]);
      }
    }
    let tagList = [...new Set(tempList)];
    tagList = [...tagList.sort()];
    let tagCell = [];

    for (let i = 0; i < tagList.length; i++) {
      tagCell.push(
        <td key={i}>
          <FormControlLabel
            label={<Typography fontSize={14}>{tagList[i]}</Typography>}
            control={
              <Checkbox
                size="small"
                checked={checkTag[i]}
                onChange={(e) => {
                  checkTag[i] = e.target.checked;
                  let arr = [...checkTag];
                  setCheckTag(arr);
                  jobTags[i] = e.target.checked ? tagList[i] : null;
                }}
              />
            }
          />
        </td>
      );
      if (i % 4 === 3) {
        rows.push(<tr key={i.toString()}>{tagCell}</tr>);
        tagCell = [];
      }
    }
    if (tagCell.length !== 0) {
      for (let j = tagCell.length; j < 4; j++) {
        tagCell.push(<td key={j.toString() + "c"}></td>);
      }
      rows.push(<tr key={tagList.length.toString()}>{tagCell}</tr>);
    }
    tagName = [...tagList];
    tagLength = tagList.length;
    return (
      <table style={{ width: "100%", textAlign: "left" }}>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  function removeQuotes(s) {
    return s && s.replaceAll("'", "");
  }
  function wrapInQuotes(s) {
    return s && `'${s}'`;
  }

  //Print unique commands names, display them in a table
  function GetCommandNames({ commands }) {
    let rows = [];
    let cells = [];
    let commandNamesTemp = [];

    for (let i = 0; i < commands.length; i++) {
      commandNamesTemp.push(wrapInQuotes(commands[i].name));
    }

    //Get unique names, and sort the array  alphabetically
    let commandNames_ = [...new Set(commandNamesTemp)];
    commandNames_ = [...commandNames_.sort()];
    nameLength = commandNames_.length;

    //creat the command name table
    for (let i = 0; i < nameLength; i++) {
      cells.push(
        <td key={i}>
          <FormControlLabel
            label={
              <Typography fontSize={14}>
                {removeQuotes(commandNames_[i])}
              </Typography>
            }
            control={
              <Checkbox
                size="small"
                checked={checkType[i]}
                onChange={(e) => {
                  checkType[i] = e.target.checked;
                  let arr = [...checkType];
                  setCheckType(arr);
                  jobTypes[i] = e.target.checked ? commandNames_[i] : null;
                }}
              />
            }
          />
        </td>
      );
      if (i % 4 === 3) {
        rows.push(<tr key={i.toString()}>{cells}</tr>);
        cells = [];
      }
    }
    if (cells.length !== 0) {
      for (let j = cells.length; j < 4; j++) {
        cells.push(<td key={j.toString() + "c"}></td>);
      }
      rows.push(<tr key={commandNames_.length.toString()}>{cells}</tr>);
    }
    commandNames = [...commandNames_];
    return (
      <table style={{ width: "100%", textAlign: "left" }}>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  //When user click reset button, set default
  function resetCheck() {
    setCheckStatus([false, false, false, false]);
    jobStatus.fill(null);

    let tempArray = checkType.fill(false);
    setCheckType([...tempArray]);
    jobTypes.fill(null);

    let tempTagArray = checkTag.fill(false);
    setCheckTag([...tempTagArray]);
    jobTags.fill(null);
  }

  //When user click the inverse button, inverse the selections
  function InverseSelect() {
    let statusTemp = [...checkStatus];
    for (let i = 0; i < statusTemp.length; i++) {
      if (statusTemp[i] === false) {
        statusTemp[i] = true;
      } else {
        statusTemp[i] = false;
      }

      if (jobStatus[i]) {
        jobStatus[i] = null;
      } else {
        jobStatus[i] = statuses[i];
      }
    }
    setCheckStatus([...statusTemp]);

    for (let i = 0; i < nameLength; i++) {
      if (
        checkType[i] === false ||
        checkType[i] === null ||
        checkType[i] === undefined
      ) {
        checkType[i] = true;
      } else {
        checkType[i] = false;
      }

      if (jobTypes[i]) {
        jobTypes[i] = null;
      } else {
        jobTypes[i] = commandNames[i];
      }
    }

    for (let i = 0; i < tagLength; i++) {
      if (
        checkTag[i] === false ||
        checkTag[i] === null ||
        checkTag[i] === undefined
      ) {
        checkTag[i] = true;
      } else {
        checkTag[i] = false;
      }

      if (jobTags[i]) {
        jobTags[i] = null;
      } else {
        jobTags[i] = tagName[i];
      }
    }
  }

  //When user click confirm button, get SQL query, set the filter list
  function confirmCheck() {
    setIntervalID1(clearInterval(intervalID1));

    setTypeList("");
    setStatusList("");
    setTagList("");
    //let ifsetInterval = false;
    let selectedType = jobTypes.filter((type) => type !== null);
    let selectedStatus = jobStatus.filter((status) => status !== null);
    let selectedTag = jobTags.filter((tag) => tag !== null);
    let ifQR = false;
    let ifFF = false;

    if (
      selectedType.length !== 0 ||
      selectedStatus.length !== 0 ||
      selectedTag.length !== 0
    ) {
      setAllJobsInfo([]);
      setAllJobsInfoQR([]);

      //set the SQL query, for Running or Queued
      let sqlQuery = `SELECT * FROM Job WHERE deleted IS FALSE AND projectId = '
        ${currProjectInfo.projectId}'`;
      //for Finished or Failed
      let sqlQuery2 = `SELECT * FROM Job WHERE deleted IS FALSE AND projectId = '
        ${currProjectInfo.projectId}'`;

      //When user selected status filters
      if (selectedStatus.length !== 0) {
        let statuses = [];
        let statusQR = [];
        let statusFF = [];
        for (let i = 0; i < selectedStatus.length; i++) {
          //If status contains Running or Queued status
          if (
            selectedStatus[i] === "'RUNNING'" ||
            selectedStatus[i] === "'QUEUED'"
          ) {
            statusQR.push(selectedStatus[i]);
          } else {
            statusFF.push(selectedStatus[i]);
          }
          statuses.push(
            <Chip
              label={removeQuotes(selectedStatus[i])}
              variant="outlined"
              key={i}
              sx={{ ml: "3px" }}
            />
          );
        }

        //If status contains Running or Queued status
        if (statusQR.length !== 0) {
          ifQR = true;
          sqlQuery = sqlQuery + " AND jobStatus IN (" + statusQR + ")";
        }
        //If status contains Finished or Failed status
        if (statusFF.length !== 0) {
          ifFF = true;
          sqlQuery2 = sqlQuery2 + " AND jobStatus IN (" + statusFF + ")";
        }
        setStatusList(<span style={{ marginLeft: "5px" }}>{statuses}</span>);
      } else {
        sqlQuery = sqlQuery + " AND jobStatus IN ('QUEUED','RUNNING')";
        sqlQuery2 = sqlQuery2 + " AND jobStatus IN ('FINISHED','FAILED')";
      }

      //If user selected type filters
      if (selectedType.length !== 0) {
        sqlQuery = sqlQuery + " AND commandName IN (" + selectedType + ")";
        sqlQuery2 = sqlQuery2 + " AND commandName IN (" + selectedType + ")";
        let types = [];
        for (let i = 0; i < selectedType.length; i++) {
          types.push(
            <Chip
              label={removeQuotes(selectedType[i])}
              variant="outlined"
              key={i}
              sx={{ ml: "3px" }}
            />
          );
        }
        setTypeList(<span style={{ marginLeft: "15px" }}>{types}</span>);
      }

      //If user selected tag filters
      if (selectedTag.length !== 0) {
        sqlQuery = sqlQuery + " AND (jobTag LIKE '%\"";
        sqlQuery2 = sqlQuery2 + " AND (jobTag LIKE '%\"";

        let tags = [];
        for (let i = 0; i < selectedTag.length; i++) {
          if (i < selectedTag.length - 1) {
            sqlQuery = sqlQuery + selectedTag[i] + "\"%' OR jobTag LIKE '%\"";
            sqlQuery2 = sqlQuery2 + selectedTag[i] + "\"%' OR jobTag LIKE '%\"";
          } else {
            sqlQuery = sqlQuery + selectedTag[i] + "\"%')";
            sqlQuery2 = sqlQuery2 + selectedTag[i] + "\"%')";
          }
          tags.push(
            <Chip
              label={selectedTag[i]}
              variant="outlined"
              key={i}
              sx={{ ml: "3px" }}
            />
          );
        }
        setTagList(<span style={{ marginLeft: "15px" }}>{tags}</span>);
      }
      //If the filter contains only Queued or Running jobs
      if (!ifFF && ifQR) {
        fetchJobsQR(sqlQuery);
        setIntervalID1(setInterval(() => fetchJobsQR(sqlQuery), 2000));
      }
      //If the filter contains only Finished or failed jobs
      else if (ifFF && !ifQR) {
        fetchJobsFE(sqlQuery2);
        setIntervalID1(setInterval(() => fetchJobsFE(sqlQuery2), 2000));
      } else {
        //If user did not choose status filter or choose all filters,
        fetchJobsQR(sqlQuery);
        fetchJobsFE(sqlQuery2);
        setIntervalID1(
          setInterval(() => {
            fetchJobsQR(sqlQuery);
            fetchJobsFE(sqlQuery2);
          }, 10000)
        );
      }
    }

    handleFilterCloseConfirm();
  }

  //When user clicked all jobs button
  function DisplayAlljob() {
    setIntervalID1(clearInterval(intervalID1));

    setTypeList("");
    setStatusList("");
    setTagList("");

    clearInterval(intervalID1);

    setCheckStatus([false, false, false, false]);
    jobStatus.fill(null);

    let tempArray = checkType.fill(false);
    setCheckType([...tempArray]);
    jobTypes.fill(null);

    let tempTagArray = checkTag.fill(false);
    setCheckTag([...tempTagArray]);
    jobTags.fill(null);

    //Fetch the jobs and set interval for Running or Queued jobs
    fetchJobsFE(sqlQueryFE);
    fetchJobsQRIDs(sqlQueryQR);

    //Fetch jobs by ids each 10s
    if (jobsIDQuery) {
      setIntervalID1(setInterval(() => fetchJobsQR(jobsIDQuery), 10000));
    }
  }

  //handle toggle button
  const [selected, setSelected] = useState([]);
  const [treeJob, setTreeJob] = useState(undefined);

  // Handle tree view open  and close
  const [treeOpen, setTreeOpen] = useState(false);
  const [parentTree, setParentTree] = useState({});

  function handleTreeClose() {
    setTreeOpen(false);
    setParentTree({});
  }

  //Display selected filters
  let selectedFilters;
  if (statusList || tagList || typeList) {
    selectedFilters = (
      <Box
        sx={{
          width: "70%",
          verticalAlign: "center",
          whiteSpace: "nowrap",
          overflowX: "auto",
          overflowY: "hidden",
          textAlign: "right",
        }}
      >
        {statusList} {tagList} {typeList}
      </Box>
    );
  }

  //Display job ancestor tree
  function DisplayJobTree() {
    if (parentTree.parent) {
      function nodes2(children) {
        return <ul>{children.map((n, i) => nodes1(n, i))}</ul>;
      }
      function nodes1(node, i) {
        return (
          <li key={i}>
            <span className="tf-nc">
              <span className="node-text">
                {" "}
                <Grid container>{RenderTreeCard(node.parent, node.image)}</Grid>
              </span>
            </span>
            {node.children.length !== 0 ? nodes2(node.children) : ""}
          </li>
        );
      }
      return (
        <ul>
          <li>
            <span className="tf-nc">
              <span className="node-text">
                {" "}
                <Grid container>
                  {RenderTreeCard(parentTree.parent, parentTree.image)}
                </Grid>
              </span>
            </span>
            {nodes2(parentTree.children)}
          </li>
        </ul>
      );
    }
    return <CircularProgress color="inherit" size={40} />;
  }

  //When job tree view opens, fetch job information and job images
  async function handleTreeOpen() {
    async function findParent(jobInfor) {
      let treeNodes;
      try {
        if (currProjectInfo !== undefined) {
          //Fetch job images
          var imgUrls = [];
          let jobImage = "";
          let jobId_ = jobInfor["jobId"];
          let info = (await axios.post("/api/job/jobFiles/", { jobId: jobId_ }))
            .data.data;

          // filter by "postprocess"
          if (info) {
            for (let p = 0; p < info.length; p++) {
              if (info[p].jobFilePath.split("/")[8] === "postprocess") {
                imgUrls.push({
                  img: info[p].jobFilePath,
                  title: info[p].fileName,
                });
                break;
              }
            }
          }
          if (imgUrls.length > 0) {
            jobImage = imgUrls[0].img;
          } else {
            jobImage = defaultImg;
          }

          //Set parent node
          treeNodes = { parent: jobInfor, image: jobImage, children: [] };

          const parentInfo = (
            await axios.post("/api/job/jobParent", {
              jobId: jobInfor["jobId"],
            })
          ).data;

          let tempArray = [];
          if (parentInfo.code === 0) {
            tempArray = parentInfo.data.map((parentJob) => {
              return parentJob.jobName;
            });
          } else {
            throw new Error(
              "Unexpected issue with looking for job parents info"
            );
          }

          for (let i = 0; i < tempArray.length; i++) {
            //Get children job information
            let job = (
              await axios.post("/api/job/getJobByProjectIdAndJobName", {
                projectId: currProjectInfo.projectId,
                jobName: tempArray[i],
              })
            ).data;

            if (job !== null) {
              treeNodes.children.push(await findParent(job.data));
            }
          }
        }
      } catch (e) {
        setSnackbarOpen(true);
        setSnackbarMes("Failed to retrive Job parents: " + e);
      }
      return treeNodes;
    }

    setTreeOpen(true);
    setParentTree(await findParent(treeJob));
  }

  //Display jobs
  function DisplayJobs() {
    let i = 0;
    let allJobsInfoQRArray = [];
    let allJobsInfoArray = [];

    for (let j = 0; j < allJobsInfoQR.length; j++) {
      allJobsInfoQRArray.push(
        RenderCard(
          allJobsInfoQR[j],
          i,
          imagesInfoQR[j] ? imagesInfoQR[j] : defaultImg
        )
      );
      if (selected[i] === undefined) {
        selected[i] = false;
      }
      i = i + 1;
    }

    for (let k = 0; k < allJobsInfo.length; k++) {
      allJobsInfoArray.push(
        RenderCard(
          allJobsInfo[k],
          i,
          imagesInfo[k] ? imagesInfo[k] : defaultImg
        )
      );
      if (selected[i] === undefined) {
        selected[i] = false;
      }
      i = i + 1;
    }
    return (
      <div>
        {allJobsInfo.length === 0 && allJobsInfoQR.length === 0 && (
          <Alert severity="info">No job was found!</Alert>
        )}

        {allJobsInfoQR.length !== 0 && (
          <Divider sx={{ padding: "5px 50px 10px 50px" }}>
            <Typography>Queued and Running Jobs</Typography>
          </Divider>
        )}
        <Grid container>{allJobsInfoQRArray}</Grid>

        {allJobsInfo.length !== 0 && (
          <Divider sx={{ padding: "15px 50px 10px 50px" }}>
            <Typography>Finished and Failed Jobs</Typography>
          </Divider>
        )}

        <Grid container>{allJobsInfoArray}</Grid>
      </div>
    );
  }

  //Display the timers on jobcards
  function timer(jobinfor_) {
    if (
      (jobinfor_["jobStatus"] === "FINISHED" ||
        jobinfor_["jobStatus"] === "FAILED") &&
      jobinfor_["finishTime"] !== null &&
      jobinfor_["startTime"] !== null
    ) {
      let time = jobinfor_["finishTime"] - jobinfor_["startTime"];
      time = time / 60000;
      let minute = Math.round(time % 60);
      let hour = Math.floor(time / 60);
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      return hour + ":" + minute;
    } else if (
      jobinfor_["jobStatus"] === "RUNNING" &&
      jobinfor_["startTime"] !== null
    ) {
      let time = new Date().getTime() - jobinfor_["startTime"];

      time = time / 60000;
      let minute = Math.round(time % 60);
      let hour = Math.floor(time / 60);
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      return hour + ":" + minute;
    } else {
      return "00:00";
    }
  }

  // pass in the info for each card and return the card component
  //Display job cards
  function RenderCard(jobInfo, i, images) {
    return (
      <Grid item key={i}>
        <Paper
          variant="outlined"
          square
          sx={{
            m: "0px 5px 5px 0px",
            width: "195px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item>
              <ToggleButton
                value={jobInfo["jobName"]}
                size="small"
                sx={{ border: "0", padding: "0 0 0 0", marginLeft: "3px" }}
                selected={selected[i]}
                onChange={() => {
                  let temp = [...selected];
                  let temp2 = temp.fill(false);
                  temp2[i] = !selected[i];
                  if (!temp2[i]) {
                    setTreeJob(undefined);
                    setSelected(temp2.fill(false));
                  } else {
                    setTreeJob(jobInfo);
                    setSelected(temp2);
                  }
                }}
              >
                <Typography fontSize={11}>{jobInfo["jobName"]}</Typography>{" "}
                <StatusIndicator jobStatus={jobInfo.jobStatus} />
              </ToggleButton>
            </Grid>

            <Typography fontSize={11} sx={{ padding: "8px 0 8px 0" }}>
              {timer(jobInfo)}
            </Typography>
            <Grid>
              <Chip
                style={{ maxWidth: "80px", marginRight: "1px" }}
                size="small"
                label={
                  <Typography fontSize={11} sx={{ padding: "8px 0 8px 0" }}>
                    {jobInfo["commandName"]}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexWrap: "wrap", borderRadius: 2 }}>
            <ImageButton
              focusRipple
              style={{
                width: "100%",
              }}
              onClick={() => handleClickOpen(jobInfo)}
            >
              <ImageSrc style={{ backgroundImage: `url(${images})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
            </ImageButton>
          </Box>
        </Paper>
      </Grid>
    );
  }

  //Display job cards on tree view
  function RenderTreeCard(jobInfo, image_) {
    return (
      <Grid item>
        <Paper
          variant="outlined"
          square
          sx={{
            m: "0px 5px 5px 0px",
            width: "195px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item>
              <Button disabled>
                {" "}
                {jobInfo["jobName"]}
                <StatusIndicator jobStatus={jobInfo.jobStatus} />
              </Button>
            </Grid>
            <Grid>
              <Chip
                style={{ marginRight: "5px", maxWidth: "115px" }}
                size="small"
                label={jobInfo["commandName"]}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexWrap: "wrap", borderRadius: 2 }}>
            <ImageButton
              focusRipple
              style={{
                width: "100%",
              }}
              onClick={() => handleClickOpen(jobInfo)}
            >
              <ImageSrc style={{ backgroundImage: `url(${image_})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
            </ImageButton>
          </Box>
        </Paper>
      </Grid>
    );
  }

  //The dot colors on job cards
  const StatusIndicator = (prop) => {
    const switchColor = (status) => {
      switch (status) {
        case "QUEUED":
          return "#b66dff";
        case "RUNNING":
          return "#006ddb";
        case "FAILED":
          return "#920000";
        case "FINISHED":
          return "#22cf22";
        default:
          break;
      }
    };
    return (
      <FiberManualRecordIcon
        fontSize="small"
        sx={{ color: switchColor(prop.jobStatus) }}
      />
    );
  };

  return (
    <div>
      <div>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            pb: "5px",
            mb: "5px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{ width: "100%", verticalAlign: "middle" }}
          >
            <Box>
              <Button
                variant="outlined"
                startIcon={<DashboardCustomizeOutlinedIcon />}
                size="small"
                onClick={DisplayAlljob}
              >
                All Jobs
              </Button>
              <Button
                variant="outlined"
                startIcon={<FilterAltOutlinedIcon />}
                size="small"
                sx={{ ml: "10px" }}
                onClick={handleFilterOpen}
              >
                Filter
              </Button>
              <Button
                variant="outlined"
                startIcon={<AccountTreeIcon />}
                size="small"
                sx={{ ml: "10px" }}
                onClick={handleTreeOpen}
                disabled={treeJob ? false : true}
              >
                Tree
              </Button>
            </Box>
            {selectedFilters}
          </Stack>
        </Box>

        <Dialog
          open={filterOpen}
          onClose={handleFilterClose}
          fullWidth
          maxWidth={"lg"}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              All filters
              <Box>
                <Button onClick={resetCheck} sx={{ marginRight: "10px" }}>
                  Reset
                </Button>
                <Button onClick={InverseSelect} sx={{ marginRight: "50px" }}>
                  Inverse
                </Button>
                <IconButton
                  onClick={handleFilterClose}
                  sx={{ cursor: "pointer" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
          </DialogTitle>
          <div
            style={{
              width: "90%",
              overflow: "scroll",
              overflowX: "auto",
              margin: "auto",
            }}
          >
            <Divider textAlign="left">
              <Typography fontSize={14} fontWeight="bold">
                Job Status
              </Typography>
            </Divider>

            <Box
              sx={{
                width: "100%",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              <table style={{ width: "100%", textAlign: "left" }}>
                <tbody>
                  <tr>
                    <td>
                      <FormControlLabel
                        label={
                          <Typography fontSize={14}>
                            {removeQuotes(statuses[0])}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={checkStatus[0]}
                            onChange={(e) => {
                              checkStatus[0] = e.target.checked;
                              let arr = [...checkStatus];
                              setCheckStatus(arr);
                              jobStatus[0] = e.target.checked
                                ? statuses[0]
                                : null;
                            }}
                          />
                        }
                      />
                    </td>
                    <td>
                      <FormControlLabel
                        label={
                          <Typography fontSize={14}>
                            {removeQuotes(statuses[1])}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={checkStatus[1]}
                            onChange={(e) => {
                              checkStatus[1] = e.target.checked;
                              let arr = [...checkStatus];
                              setCheckStatus(arr);
                              jobStatus[1] = e.target.checked
                                ? statuses[1]
                                : null;
                            }}
                          />
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FormControlLabel
                        label={
                          <Typography fontSize={14}>
                            {removeQuotes(statuses[2])}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={checkStatus[2]}
                            onChange={(e) => {
                              checkStatus[2] = e.target.checked;
                              let arr = [...checkStatus];
                              setCheckStatus(arr);
                              jobStatus[2] = e.target.checked
                                ? statuses[2]
                                : null;
                            }}
                          />
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FormControlLabel
                        label={
                          <Typography fontSize={14}>
                            {removeQuotes(statuses[3])}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={checkStatus[3]}
                            onChange={(e) => {
                              checkStatus[3] = e.target.checked;
                              let arr = [...checkStatus];
                              setCheckStatus(arr);
                              jobStatus[3] = e.target.checked
                                ? statuses[3]
                                : null;
                            }}
                          />
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <Divider textAlign="left">
              <Typography fontSize={14} fontWeight="bold">
                Job Tags
              </Typography>
            </Divider>

            <Box
              sx={{
                width: "100%",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              <GetTaglist jobtagsName_={jobTagsName} />
            </Box>

            <Divider textAlign="left">
              <Typography fontSize={14} fontWeight="bold">
                Job Types
              </Typography>
            </Divider>

            <Box
              sx={{
                width: "100%",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              <GetCommandNames commands={commands} />
            </Box>
          </div>

          <Divider sx={{ marginBottom: "20px" }} />

          <DialogActions>
            <Button
              variant="outlined"
              onClick={confirmCheck}
              sx={{ margin: "0 50px 20px 0" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={treeOpen}
          onClose={handleTreeClose}
          fullWidth
          maxWidth={"lg"}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              Job Tree
              <Box>
                <IconButton
                  onClick={handleTreeClose}
                  sx={{ cursor: "pointer" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
          </DialogTitle>
          <div style={{ margin: "auto" }}>
            <div
              className="tf-tree tf-ancestor-tree"
              style={{ minHeight: "100px", minWidth: "200px" }}
            >
              <DisplayJobTree />
            </div>
            <div style={{ width: "100%", height: "50px" }}></div>
          </div>
        </Dialog>
      </div>

      <DisplayJobs />
    </div>
  );
}
