import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
} from "@mui/material";
import { Fragment, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";

export default function ProjectCreate({ projects, onCreateProject }) {
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [working, setWorking] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMes, setSnackbarMes] = useState("");

  // set error based on projectName input
  let error = undefined;
  if (projects.includes(projectName)) {
    error = "Project with that name already exists";
  } else if (projectName.includes(" ")) {
    error = "Project name can't have spaces";
  } else if (projectName === "") {
    error = "Project name can't be blank";
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSnackbarOpen(flag) {
    setSnackbarOpen(flag);
  }

  async function createProject() {
    setWorking(true);
    try {
      let userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : Cookies.get('userId');
      const form = {
        userId,
        projectName,
        projectDescription,
      };
      const ps = await axios.post("/api/project/createproject", form);
      if (ps.data.code === 0) {
        onCreateProject(projectName);
        handleClose();
      }
      handleSnackbarOpen(true);
      setSnackbarMes(ps.data.msg);
    } catch (e) {
      handleSnackbarOpen(true);
      setSnackbarMes("Failed to create project: " + e);
    } finally {
      setProjectName("");
      setProjectDescription("");
      setWorking(false);
    }
  }

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<CreateNewFolderOutlinedIcon />}
        disabled={localStorage.getItem("userId") === null && Cookies.get('userId') == null}
        onClick={() => setOpen(true)}
      >
        New
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogContent>
          <TextField
            error={!!error}
            helperText={error}
            autoFocus
            margin="normal"
            id="name"
            label="Project Name"
            fullWidth
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}
          />
          <TextField
            label="(Optional) Project Description"
            multiline
            fullWidth
            margin="normal"
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={createProject} disabled={!!error}>
            {!working && "Create"}
            {working && (
              <Fragment>
                Creating... <CircularProgress color="inherit" size={20} />
              </Fragment>
            )}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          handleSnackbarOpen(false);
        }}
        message={snackbarMes}
      />
    </div>
  );
}
