import { useState } from "react";
import { Stack, Paper, Box } from "@mui/material";
import YamlEditor from "./YamlEditor/YamlEditor";
import placeholder from "./YamlEditor/placeholder";
import CmdPreview from "./CmdPreview/CmdPreview";

function YmalHub() {
  const [ymalRawData, setYmalRawData] = useState(placeholder);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      {/* <Box sx={{ width: "33%" }}>
        <Paper elevation={1} sx={{ height: "700px" }}>
          <Typography variant="h5" component="div">
            Notes/Tools(TODO)
          </Typography>
        </Paper>
      </Box> */}
      <Box sx={{ width: "65%" }}>
        <Paper elevation={1} sx={{ minHeight: "700px" }}>
          <YamlEditor
            ymalRawData={ymalRawData}
            setYmalRawData={setYmalRawData}
          />
        </Paper>
      </Box>
      <Box sx={{ width: "33%" }}>
        <Paper elevation={1} sx={{}}>
          <CmdPreview ymalRawData={ymalRawData} />
        </Paper>
      </Box>
    </Stack>
  );
}

export default YmalHub;
