import { useState } from "react";
import axios from "axios";
import {
  Alert,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useDispatch, useSelector } from "react-redux";
import { modifyCurrProjectInfo } from "../../currProjectInfoSlice";

export default function ProjectInfo({ disabledBtn }) {
  const [open, setOpen] = useState(false);
  const [openDesEditModel, setOpenDesEditModel] = useState(false);
  const dispatch = useDispatch();
  const currProjectInfo = useSelector((state) => state.currProjectInfo.value);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDesEditModelClickOpen = () => {
    setOpenDesEditModel(true);
  };

  const handleDesEditModelClose = () => {
    setOpenDesEditModel(false);
  };

  const handleSubmitEdit = async (newDescription) => {
    handleDesEditModelClose();
    handleClose();
    dispatch(
      modifyCurrProjectInfo({
        item: "projectDescription",
        newValue: newDescription,
      })
    );
  };

  const displayRelativePath = (str) => {
    const splitedStr = str.split("/");
    return `./${splitedStr[splitedStr.length - 2]}/${
      splitedStr[splitedStr.length - 1]
    }`;
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<DescriptionOutlinedIcon />}
        onClick={handleClickOpen}
        disabled={disabledBtn}
      >
        Note
      </Button>
      {open && (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth
            aria-labelledby="project-information"
            aria-describedby="project-information"
          >
            <DialogTitle>Project Information</DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1">Relative Path</Typography>
              <DialogContentText gutterBottom>
                {displayRelativePath(currProjectInfo.projectPath)}
              </DialogContentText>
              <>
                <Typography variant="subtitle1">
                  Project Description
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    component="label"
                    onClick={handleDesEditModelClickOpen}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Typography>

                {currProjectInfo.projectDescription === "" ? (
                  <Alert severity="info">
                    No Project Description. Click icon above to edit.
                  </Alert>
                ) : (
                  <DialogContentText
                    gutterBottom
                    sx={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}
                  >
                    {currProjectInfo.projectDescription}
                  </DialogContentText>
                )}
              </>
            </DialogContent>
          </Dialog>
          {openDesEditModel && (
            <EditDescModel
              projectId={currProjectInfo.projectId}
              openDesEditModel={openDesEditModel}
              handleDesEditModelClose={handleDesEditModelClose}
              defaultVal={currProjectInfo.projectDescription}
              handleSubmitEdit={handleSubmitEdit}
            />
          )}
        </>
      )}
    </>
  );
}

function EditDescModel({
  projectId,
  openDesEditModel,
  handleDesEditModelClose,
  defaultVal,
  handleSubmitEdit,
}) {
  const [projectDescription, setProjectDescription] = useState(defaultVal);

  const handleEditClick = async () => {
    const form = {
      projectId,
      projectDescription,
    };
    await axios.post("/api/project/editproject", form);
    handleSubmitEdit(projectDescription);
  };

  return (
    <Dialog fullWidth open={openDesEditModel} onClose={handleDesEditModelClose}>
      <DialogTitle>Edit</DialogTitle>
      <DialogContent>
        <TextField
          label="(Optional) Project Description"
          sx={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}
          multiline
          fullWidth
          margin="normal"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDesEditModelClose} color="error">
          Cancel
        </Button>
        <Button onClick={handleEditClick}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
